const initialState = {
  isValidUser: false,
  isProfileCall: false,
  isGuestFlow: false,
  isSignApplied: false,
  redeemedAmount:0,
  walletAmount:0,
  redeemedStatus: false,
  orderSmryRedeemedAmount: 0,
  nonVvcAccount: false,
  getVisaCardCall: false,
  holidayOfferProducts:[],
  holidayOfferCall:false
};
function categories(state = initialState, action) {
  switch (action.type) {
    case 'CATEGORIESFROMAPI':
      return Object.assign({}, state, {
        categoriesExp: action.value,
      });
    case 'BANNERFROMAPI':
      return Object.assign({}, state, {
        bannerExp: action.value,
      });
    case 'FEATUREDACCESSORIESFROMAPI':
      return Object.assign({}, state, {
        featuredAccessoriesExp: action.value,
      });
    case 'STORECATEGORYID': {
      //  Object.assign( ...state, {
      //     categoryId: action.value,
      //  })
      //  return state;
      // }
      return { ...state, categoryDetails: action.value };
    }
    case 'UPDATEPAGENAME': {
      //  Object.assign( ...state, {
      //     categoryId: action.value,
      //  })
      //  return state;
      // }
      return { ...state, pageName: action.value };
    }
    case 'VALIDUSER': {
      return Object.assign({}, state, {
        isValidUser: action.value,
      });
    }
    case 'PROFILECALL': {
      return Object.assign({}, state, {
        isProfileCall: action.value,
      });
    }
    case 'ISGUESTFLOW': {
      return Object.assign({}, state, {
        isGuestFlow: action.value,
      });
    }
    case 'ISSIGNINAPPLIED': {
      return Object.assign({}, state, {
        isSignApplied: action.value,
      });
    }
    case 'STANDARDERRORSFROMAPI': {
      return { ...state, standardErrors: action.value };
    }
    case 'DIGITALVISACARDINFOCALL': {
      return Object.assign({}, state, {
        visaCardInfo: action.value,
      });
    }
    case 'NONVVCACCOUNT': {
      return Object.assign({}, state, {
        nonVvcAccount: action.value,
      });
    }
    case 'VVCACCOUNTVISACARD': {
      return Object.assign({}, state, {
        getVisaCardCall: action.value,
      });
    }
    case 'CUSTOMERWALLETAMOUNT': {
      return Object.assign({}, state, {
        walletAmount: action.value,
      });
    }
    case 'CUSTOMERREDEEMEDAMOUNT': {
      return Object.assign({}, state, {
        redeemedAmount: action.value,
      });
    }
    case 'REDEEMEDSTATUS': {
      return Object.assign({}, state, {
        redeemedStatus: action.value,
      });
    }
    case 'ORDERSMRYREDEEMEDAMOUNT': {
      return Object.assign({}, state, {
        orderSmryRedeemedAmount: action.value,
      });
    }
    case 'HOLIDAYOFFERPRODUCTS': {
      if(!state.holidayOfferProducts.includes(action.value)){
        return{
          ...state,holidayOfferProducts:[...state.holidayOfferProducts, action.value]
        }
      }
      return state;
    }
    case 'HOLIDAYOFFERCALL': {
      return { ...state, holidayOfferCall: action.value };
    }
    default:
      return state;
  }
}
export default categories;
