import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducers';
import reducerSMB from './reducersSMB';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import './assets/css/app/app.css';
import './assets/css/vzrf/vzrf.min.css';
import './assets/css/app/vtpDev.css';
import './assets/css/vzrf/carousel.min.css';
import './assets/css/vzrf/off-canvas.min.css';
import './assets/css/vzrf/top-bar.min.css';
import ErrorBoundary from './service/errorBoundary';
// const appHost = window.location.host
// if(appHost === "localhost:3000" || appHost === "wwwnte1aws.ebiz.verizon.com" || appHost === "wwwawssit.ebiz.verizon.com"){
// // const React = require('react');
// const ReactDOM = require('react-dom');
// //if (process.env.NODE_ENV !== 'production') {
//   const axe = require('@axe-core/react');
//   axe(React, ReactDOM, 1000);
// //}
// }
const persistConfigVTP = {
  key: 'VTP',
  storage: storage,
  //transforms: [transformer]
};
const persistConfigSMB = {
  key: 'VTP_SMB',
  storage: storage,
};
let reducerName;
let persistConfig;
let AppName = window.location.pathname;
if (AppName.indexOf('home') > -1) {
  reducerName = reducer;
  persistConfig = persistConfigVTP;
} else if (AppName.indexOf('smallbusiness') > -1) {
  reducerName = reducerSMB;
  persistConfig = persistConfigSMB;
} else {
  //else case added for consumer app JEST execution.
  reducerName = reducer;
  persistConfig = persistConfigVTP;
}
const persistedReducer = persistReducer(persistConfig, reducerName);

const history = createBrowserHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//export const store = createStore(connectRouter(history),rootReducer,composeEnhancer());
export const store = createStore(connectRouter(history)(persistedReducer), composeEnhancer(applyMiddleware(routerMiddleware(history))));
// export const store = createStore(
//   rootReducer(history),
//   applyMiddleware(routerMiddleware(history))
// )
let persistor = persistStore(store);
render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
    <ErrorBoundary><App history={history} /></ErrorBoundary>
    </PersistGate>
  </Provider>,
  document.getElementById('root') || document.createElement('div'),
);
