//the url configs for different environments for all the atom and aem endpoints

const sitProxy = {
  tokenGeneration: 'https://atomsessionmanagernte1.cfappsawsnpwest.ebiz.verizon.com/generatetoken',
  createVisitId: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/sessionmanager/visitid',
  categoriesUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/categories/',
  featuredAccessoriesUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/featuredAccessories/',
  bannerUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/banner/',
  productsUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/products/',
  productDetails: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/productDetails/',
  updateCart: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/cart/cart/accordion/updatecart',
  //"myVzSignIn": "https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&mode=i&clientId=Y&customerType=DE&hideRegistration=Y&fromVZT=Y&hideTitle=Y&userNameOnly=false&goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwawssit.ebiz.verizon.com%252Fhome%252Faccessories%252Fscheckout%252Fshipping.jsp",
  myVzSignIn:
    'https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://wwwawssit.ebiz.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwnte1aws.ebiz.verizon.com%252Fhome%252Faccessories%252Fs%252Fcheckout',
  profileInfo: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/customer/profile',
  validateName: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/contactinfovalidation/order/validateName',
  validateContactInfo: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/contactinfovalidation/order/contact',
  validateBillingAdress: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/billingvalidation/order/billing',
  shipping: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/shipping/',
  vepsUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/veps/vtpveps/payment',
  pieUrl: 'https://pie.uat.verizon.com/pie/v1',
  kafkaPageLogs: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/common/kafka/atom_pagelogs',
  getTaxUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/soc/tax/getTax',
  getMonUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/mon/order/accessoriesmon',
  orderSubmit: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/ordersubmission/order/submit',
  // "kafkaPageLogs": "https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/common/kafka/atom_pagelogs",
  kafkaErrorAppLogs: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/common/kafka/atom_applogs',
  cwcorderassessment: 'https://api-dev.ebiz.verizon.com/atomapi/v1/creditcheckservice/creditcheck/cwcorderassessment',
  cardinalURL: 'https://includestest.ccdc02.com/cardinalcruise/v1/songbird.js',
  jwtToken: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/veps/vtpveps/getjwttoken',
  vepsLookup: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/veps/vtpveps/payment',
  getCart: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/cart/cart/',
  updateIntent: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/cart/cart/accordion/updateintent',
  shoppingCartContentUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/shoppingCart/',
  orderSubmissionPageContent:
    'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/orderSubmissionPageContent/',
  beginCheckoutContent: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/guestCheckout/',
  faqUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/faqs/',
  headerContentUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/toolBarShippingItem/',
  standardErrors: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/standardErrors/',
  updateFlowType: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/sessionmanager/updateflowtype',
  checkoutPageContent: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/checkoutPageContent/',
  searchUrl: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/getAccessoriesProducts?catId=cat80001',
  vtpErrors: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/vtpErrors/',
  updateVisitDetails: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/sessionmanager/updatevisitdetails',
  jsNotify: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/common/logger/logmsg',
  checkUserCreditCardInfo: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/checkUserCreditCardInfo/',
  oneTimeRedemption: 'https://atomservicesekssiteast.ebiz.verizon.com/v05g/sit/accessoriesqualification/accessories/oneTimeRedemption/',
  applyVvcUrl:'https://vzwqa1.verizonwireless.com/solutions-and-services/verizon-visa-card/?source=fiosvvc',
  vvcRewardsUrl:'https://vzwqa1.verizonwireless.com/support/verizon-visa-card-rewards-legal/'
};

const sitApigee = {
  tokenGeneration: '/home/accessories/generatetoken',
  createVisitId: '/home/accessories/generatevisitid',
  cacheRefresh: '/home/accessories/clearcache',
  categoriesUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/categories/',
  featuredAccessoriesUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/featuredAccessories/',
  bannerUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/banner/',
  productsUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/products/',
  productDetails: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/productDetails/',
  updateCart: 'https://api-qa.ebiz.verizon.com/atomapi/v1/cart/cart/accordion/updatecart',
  //"myVzSignIn": "https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&mode=i&clientId=Y&customerType=DE&hideRegistration=Y&fromVZT=Y&hideTitle=Y&userNameOnly=false&goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwawssit.ebiz.verizon.com%252Fhome%252Faccessories%252Fscheckout%252Fshipping.jsp",
  myVzSignIn:'https://securesit1.verizon.com/signin?goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwawssit.ebiz.verizon.com%252Fhome%252Faccessories%252Fs%252Fcheckout&mode=i&clientId=wln-accessories&hideRegistration=true&hideTitle=true',
  // "profileInfo": "https://atomserviceswestsit.ebiz.verizon.com/v05g/sit/accessoriesqualificationservice/customer/profile",
  profileInfo: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/customer/profile',
  kafkaErrorAppLogs: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  validateName: 'https://api-qa.ebiz.verizon.com/atomapi/v1/contactinfo/order/validateName',
  validateContactInfo: 'https://api-qa.ebiz.verizon.com/atomapi/v1/contactinfo/order/contact',
  validateBillingAdress: 'https://api-qa.ebiz.verizon.com/atomapi/v1/billing/order/billing',
  shipping: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/shipping/',
  vepsUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/veps/vtpveps/payment',
  pieUrl: 'https://pie.uat.verizon.com/pie/v1',
  kafkaPageLogs: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getMonUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/masterordernumber/order/accessoriesmon',
  orderSubmit: 'https://api-qa.ebiz.verizon.com/atomapi/v1/order/order/submit',
  cardinalURL: 'https://includestest.ccdc02.com/cardinalcruise/v1/songbird.js',
  jwtToken: 'https://api-qa.ebiz.verizon.com/atomapi/v1/veps/vtpveps/getjwttoken',
  vepsLookup: 'https://api-qa.ebiz.verizon.com/atomapi/v1/veps/vtpveps/payment',
  getCart: 'https://api-qa.ebiz.verizon.com/atomapi/v1/cart/cart/',
  getTaxUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/soc/tax/getTax',
  updateIntent: 'https://api-qa.ebiz.verizon.com/atomapi/v1/cart/cart/accordion/updateintent',
  // "kafkaPageLogs": "https://api-qa.ebiz.verizon.com/atomapi/v1/common/kafka/atom_pagelogs",
  cwcorderassessment: 'https:api-qa.ebiz.verizon.com/atomapi/v1/creditcheckservice/creditcheck/cwcorderassessment',
  shoppingCartContentUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/shoppingCart/',
  beginCheckoutContent: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/guestCheckout/',
  // "shoppingCartContentUrl": "https://api-qa.ebiz.verizon.com/atomapi/v1/qualifiedproducts/accessories/shoppingCart/",
  //"shoppingCartContentUrl": "https://atomserviceswestsit.ebiz.verizon.com/v05g/sit/product/accessories/shoppingCart/",
  faqUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/faqs/',
  headerContentUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/toolBarShippingItem/',
  standardErrors: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/standardErrors/',
  orderSubmissionPageContent: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/orderSubmissionPageContent/',
  checkoutPageContent: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkoutPageContent/',
  updateFlowType: 'https://api-qa.ebiz.verizon.com/atomapi/v1/sessionmanager/updateflowtype',
  searchUrl: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/getAccessoriesProducts?catId=cat80001',
  vtpErrors: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/vtpErrors/',
  updateVisitDetails: 'https://api-qa.ebiz.verizon.com/atomapi/v1/sessionmanager/updatevisitdetails',
  jsNotify: 'https://api-qa.ebiz.verizon.com/atomapi/v1/common/logger/logmsg',
  checkUserCreditCardInfo: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkUserCreditCardInfo/',
  oneTimeRedemption: 'https://api-qa.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/oneTimeRedemption/',
  myOneVzRoutingUrl: 'https://sso-np.ebiz.verizon.com/consumer/myverizon/router?target=https://wwwawssit.ebiz.verizon.com/home/accessories/',
  applyVvcUrl:'https://vzwqa1.verizonwireless.com/solutions-and-services/verizon-visa-card/?source=fiosvvc',
  vvcRewardsUrl:'https://vzwqa1.verizonwireless.com/support/verizon-visa-card-rewards-legal/'
};

const nte1Proxy = {
  tokenGeneration: 'https://atomsessionmanagernte1.cfappsawsnpwest.ebiz.verizon.com/generatetoken',
  createVisitId: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/sessionmanager/visitid',
  categoriesUrl: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/categories/',
  featuredAccessoriesUrl: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/featuredAccessories/',
  bannerUrl: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/banner/',
  productsUrl: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/products/',
  productDetails: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/productDetails/',
  updateCart: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/cart/cart/accordion/updatecart',
  //"myVzSignIn": "https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&mode=i&clientId=Y&customerType=DE&hideRegistration=Y&fromVZT=Y&hideTitle=Y&userNameOnly=false&goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwawssit.ebiz.verizon.com%252Fhome%252Faccessories%252Fscheckout%252Fshipping.jsp",
  myVzSignIn:
    'https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://wwwawssit.ebiz.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwnte1aws.ebiz.verizon.com%252Fhome%252Faccessories%252Fs%252Fcheckout',
  profileInfo: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/customer/profile',
  validateName: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/contactinfovalidation/order/validateName',
  validateContactInfo: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/contactinfovalidation/order/contact',
  validateBillingAdress: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/billingvalidation/order/billing',
  shipping: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/shipping/',
  vepsUrl: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/veps/vtpveps/payment',
  pieUrl: 'https://pie.uat.verizon.com/pie/v1',
  kafkaPageLogs: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/common/kafka/atom_pagelogs',
  getTaxUrl: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/soc/tax/getTax',
  getMonUrl: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/mon/order/accessoriesmon',
  orderSubmit: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/ordersubmission/order/submit',
  // "kafkaPageLogs": "https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/common/kafka/atom_pagelogs",
  kafkaErrorAppLogs: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/common/kafka/atom_applogs',
  cwcorderassessment: 'https://api-dev.ebiz.verizon.com/atomapi/v1/creditcheckservice/creditcheck/cwcorderassessment',
  cardinalURL: 'https://includestest.ccdc02.com/cardinalcruise/v1/songbird.js',
  jwtToken: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/veps/vtpveps/getjwttoken',
  vepsLookup: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/veps/vtpveps/payment',
  getCart: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/cart/cart/',
  updateIntent: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/cart/cart/accordion/updateintent',
  shoppingCartContentUrl: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/shoppingCart/',
  orderSubmissionPageContent:
    'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/orderSubmissionPageContent/',
  beginCheckoutContent: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/guestCheckout/',
  faqUrl: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/faqs/',
  headerContentUrl: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/toolBarShippingItem/',
  standardErrors: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/standardErrors/',
  updateFlowType: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/sessionmanager/updateflowtype',
  checkoutPageContent: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/checkoutPageContent/',
  searchUrl: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/getAccessoriesProducts?catId=cat80001',
  vtpErrors: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/vtpErrors/',
  updateVisitDetails: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/sessionmanager/updatevisitdetails',
  jsNotify: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/common/logger/logmsg',
  checkUserCreditCardInfo: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/checkUserCreditCardInfo/',
  oneTimeRedemption: 'https://atomserviceseksqaeast.ebiz.verizon.com/v05g/qa/accessoriesqualification/accessories/oneTimeRedemption/',
  applyVvcUrl:'https://vzwqa1.verizonwireless.com/solutions-and-services/verizon-visa-card/?source=fiosvvc',
  vvcRewardsUrl:'https://vzwqa1.verizonwireless.com/support/verizon-visa-card-rewards-legal/'
};

const nte1Apigee = {
  tokenGeneration: '/home/accessories/generatetoken',
  createVisitId: '/home/accessories/generatevisitid',
  cacheRefresh: '/home/accessories/clearcache',
  categoriesUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/categories/',
  featuredAccessoriesUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/featuredAccessories/',
  bannerUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/banner/',
  productsUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/products/',
  productDetails: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/productDetails/',
  updateCart: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/cart/cart/accordion/updatecart',
  //"myVzSignIn": "https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&mode=i&clientId=Y&customerType=DE&hideRegistration=Y&fromVZT=Y&hideTitle=Y&userNameOnly=false&goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwawssit.ebiz.verizon.com%252Fhome%252Faccessories%252Fscheckout%252Fshipping.jsp",
  myVzSignIn:'https://securesit1.verizon.com/signin?goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwnte1aws.ebiz.verizon.com%252Fhome%252Faccessories%252Fs%252Fcheckout&mode=i&clientId=wln-accessories&hideRegistration=true&hideTitle=true',
  profileInfo: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/customer/profile',
  validateName: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/contactinfo/order/validateName',
  validateContactInfo: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/contactinfo/order/contact',
  validateBillingAdress: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/billing/order/billing',
  shipping: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/shipping/',
  vepsUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/veps/vtpveps/payment',
  pieUrl: 'https://pie.uat.verizon.com/pie/v1',
  kafkaPageLogs: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getMonUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/masterordernumber/order/accessoriesmon',
  orderSubmit: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/order/order/submit',
  kafkaErrorAppLogs: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  cwcorderassessment: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/creditcheckservice/creditcheck/cwcorderassessment',
  //"profileInfo":"https://api-dev.ebiz.verizon.com/atomapi/v1/accessoriesqualification/customer/profile",
  cardinalURL: 'https://includestest.ccdc02.com/cardinalcruise/v1/songbird.js',
  jwtToken: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/veps/vtpveps/getjwttoken',
  vepsLookup: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/veps/vtpveps/payment',
  getCart: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/cart/cart/',
  getTaxUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/soc/tax/getTax',
  updateIntent: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/cart/cart/accordion/updateintent',
  shoppingCartContentUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/shoppingCart/',
  beginCheckoutContent: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/guestCheckout/',
  faqUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/faqs/',
  headerContentUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/toolBarShippingItem/',
  standardErrors: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/standardErrors/',
  updateFlowType: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/sessionmanager/updateflowtype',
  orderSubmissionPageContent: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/orderSubmissionPageContent/',
  checkoutPageContent: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkoutPageContent/',
  searchUrl: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/getAccessoriesProducts?catId=cat80001',
  vtpErrors: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/vtpErrors/',
  updateVisitDetails: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/sessionmanager/updatevisitdetails',
  jsNotify: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/common/logger/logmsg',
  checkUserCreditCardInfo: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkUserCreditCardInfo/',
  oneTimeRedemption: 'https://api-dev2.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/oneTimeRedemption/',
  myOneVzRoutingUrl: 'https://sso-np.ebiz.verizon.com/consumer/myverizon/router?target=https://wwwawssit.ebiz.verizon.com/home/accessories/',
  applyVvcUrl:'https://vzwqa1.verizonwireless.com/solutions-and-services/verizon-visa-card/?source=fiosvvc',
  vvcRewardsUrl:'https://vzwqa1.verizonwireless.com/support/verizon-visa-card-rewards-legal/'
};

const prodApigee = {
  tokenGeneration: '/home/accessories/generatetoken',
  createVisitId: '/home/accessories/generatevisitid',
  cacheRefresh: '/home/accessories/clearcache',
  categoriesUrl: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/categories/',
  featuredAccessoriesUrl: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/featuredAccessories/',
  // "bannerUrl": "https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/banner/",
  bannerUrl: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/banner/',
  productsUrl: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/products/',
  productDetails: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/productDetails/',
  updateCart: 'https://api.verizon.com/atomapi/v1/cart/cart/accordion/updatecart',
  myVzSignIn:
    'https://secure.verizon.com/signin?goto=https%3a%2f%2fwww.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwww.verizon.com%252Fhome%252Faccessories%252Fs%252Fcheckout&mode=i&clientId=wln-accessories&hideRegistration=true&hideTitle=true',
  profileInfo: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/customer/profile',
  validateName: 'https://api.verizon.com/atomapi/v1/contactinfo/order/validateName',
  validateContactInfo: 'https://api.verizon.com/atomapi/v1/contactinfo/order/contact',
  validateBillingAdress: 'https://api.verizon.com/atomapi/v1/billing/order/billing',
  shipping: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/shipping/',
  vepsUrl: 'https://api.verizon.com/atomapi/v1/veps/vtpveps/payment',
  pieUrl: 'https://pie.prod.verizon.com/pie/v1',
  kafkaPageLogs: 'https://api.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getMonUrl: 'https://api.verizon.com/atomapi/v1/masterordernumber/order/accessoriesmon',
  orderSubmit: 'https://api.verizon.com/atomapi/v1/order/order/submit',
  kafkaErrorAppLogs: 'https://api.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  cwcorderassessment: 'https://api.verizon.com/atomapi/v1/creditcheckservice/creditcheck/cwcorderassessment',
  cardinalURL: 'https://songbird.cardinalcommerce.com/edge/v1/songbird.js',
  jwtToken: 'https://api.verizon.com/atomapi/v1/veps/vtpveps/getjwttoken',
  vepsLookup: 'https://api.verizon.com/atomapi/v1/veps/vtpveps/payment',
  getCart: 'https://api.verizon.com/atomapi/v1/cart/cart/',
  getTaxUrl: 'https://api.verizon.com/atomapi/v1/soc/tax/getTax',
  updateIntent: 'https://api.verizon.com/atomapi/v1/cart/cart/accordion/updateintent',
  shoppingCartContentUrl: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/shoppingCart/',
  beginCheckoutContent: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/guestCheckout/',
  faqUrl: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/faqs/',
  headerContentUrl: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/toolBarShippingItem/',
  standardErrors: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/standardErrors/',
  updateFlowType: 'https://api.verizon.com/atomapi/v1/sessionmanager/updateflowtype',
  orderSubmissionPageContent: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/orderSubmissionPageContent/',
  checkoutPageContent: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkoutPageContent/',
  searchUrl: 'https://api-dev.ebiz.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/getAccessoriesProducts?catId=cat80001',
  vtpErrors: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/vtpErrors/',
  updateVisitDetails: 'https://api.verizon.com/atomapi/v1/sessionmanager/updatevisitdetails',
  jsNotify: 'https://api.verizon.com/atomapi/v1/common/logger/logmsg',
  checkUserCreditCardInfo: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkUserCreditCardInfo/',
  oneTimeRedemption: 'https://api.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/oneTimeRedemption/',
  myOneVzRoutingUrl: 'https://www.verizon.com/consumer/myverizon/router?target=https://www.verizon.com/home/accessories/',
  applyVvcUrl:'https://www.verizon.com/solutions-and-services/verizon-visa-card/?source=fiosvvc',
  vvcRewardsUrl:'https://www.verizon.com/support/verizon-visa-card-rewards-legal/'
};

const preProdApigee = {
  tokenGeneration: '/home/accessories/generatetoken',
  createVisitId: '/home/accessories/generatevisitid',
  cacheRefresh: '/home/accessories/clearcache',
  categoriesUrl: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/categories/',
  featuredAccessoriesUrl: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/featuredAccessories/',
  // "bannerUrl": "https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/banner/",
  bannerUrl: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/banner/',
  productsUrl: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/products/',
  productDetails: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/productDetails/',
  updateCart: 'https://api-stg.verizon.com/atomapi/v1/cart/cart/accordion/updatecart',
  myVzSignIn: 'https://secure.verizon.com/signin?goto=https%3a%2f%2fwww.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fpreprod-east-gz.vpc.verizon.com%252Fhome%252Faccessories%252Fs%252Fcheckout&mode=i&clientId=wln-accessories&hideRegistration=true&hideTitle=true',
    // 'https://pprd-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www98.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&hideTitle=Y&hideRegistration=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fsso-np.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwww.preprod.verizon.com%252Fhome%252Faccessories%252Fs%252Fcheckout',
  profileInfo: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/customer/profile',
  validateName: 'https://api-stg.verizon.com/atomapi/v1/contactinfo/order/validateName',
  validateContactInfo: 'https://api-stg.verizon.com/atomapi/v1/contactinfo/order/contact',
  validateBillingAdress: 'https://api-stg.verizon.com/atomapi/v1/billing/order/billing',
  shipping: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/shipping/',
  vepsUrl: 'https://api-stg.verizon.com/atomapi/v1/veps/vtpveps/payment',
  pieUrl: 'https://pie.uat.verizon.com/pie/v1',
  kafkaPageLogs: 'https://api-stg.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getMonUrl: 'https://api-stg.verizon.com/atomapi/v1/masterordernumber/order/accessoriesmon',
  orderSubmit: 'https://api-stg.verizon.com/atomapi/v1/order/order/submit',
  kafkaErrorAppLogs: 'https://api-stg.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  cardinalURL: 'https://includestest.ccdc02.com/cardinalcruise/v1/songbird.js',
  jwtToken: 'https://api-stg.verizon.com/atomapi/v1/veps/vtpveps/getjwttoken',
  vepsLookup: 'https://api-stg.verizon.com/atomapi/v1/veps/vtpveps/payment',
  getCart: 'https://api-stg.verizon.com/atomapi/v1/cart/cart/',
  getTaxUrl: 'https://api-stg.verizon.com/atomapi/v1/soc/tax/getTax',
  updateIntent: 'https://api-stg.verizon.com/atomapi/v1/cart/cart/accordion/updateintent',
  shoppingCartContentUrl: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/shoppingCart/',
  beginCheckoutContent: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/guestCheckout/',
  faqUrl: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/faqs/',
  headerContentUrl: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/toolBarShippingItem/',
  standardErrors: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/standardErrors/',
  orderSubmissionPageContent: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/orderSubmissionPageContent/',
  updateFlowType: 'https://api-stg.verizon.com/atomapi/v1/sessionmanager/updateflowtype',
  checkoutPageContent: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkoutPageContent/',
  searchUrl: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/getAccessoriesProducts?catId=cat80001',
  vtpErrors: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/vtpErrors/',
  updateVisitDetails: 'https://api-stg.verizon.com/atomapi/v1/sessionmanager/updatevisitdetails',
  jsNotify: 'https://api-stg.verizon.com/atomapi/v1/common/logger/logmsg',
  checkUserCreditCardInfo: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkUserCreditCardInfo/',
  oneTimeRedemption: 'https://api-stg.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/oneTimeRedemption/',
  applyVvcUrl:'https://www.verizon.com/solutions-and-services/verizon-visa-card/?source=fiosvvc',
  vvcRewardsUrl:'https://www.verizon.com/support/verizon-visa-card-rewards-legal/'
};

const stageApigee = {
  tokenGeneration: '/home/accessories/generatetoken',
  createVisitId: '/home/accessories/generatevisitid',
  cacheRefresh: '/home/accessories/clearcache',
  categoriesUrl: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/categories/',
  featuredAccessoriesUrl: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/featuredAccessories/',
  bannerUrl: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/banner/',
  productsUrl: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/products/',
  productDetails: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/productDetails/',
  updateCart: 'https://api-uat.verizon.com/atomapi/v1/cart/cart/accordion/updatecart',
  //"myVzSignIn": "https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&mode=i&clientId=Y&customerType=DE&hideRegistration=Y&fromVZT=Y&hideTitle=Y&userNameOnly=false&goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwawssit.ebiz.verizon.com%252Fhome%252Faccessories%252Fscheckout%252Fshipping.jsp",
  myVzSignIn:
    'https://pprd-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www98.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&hideTitle=Y&hideRegistration=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fwww98.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwww98.verizon.com%252Fhome%252Faccessories%252Fs%252Fcheckout',
  profileInfo: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/customer/profile',
  validateName: 'https://api-uat.verizon.com/atomapi/v1/contactinfo/order/validateName',
  validateContactInfo: 'https://api-uat.verizon.com/atomapi/v1/contactinfo/order/contact',
  validateBillingAdress: 'https://api-uat.verizon.com/atomapi/v1/billing/order/billing',
  shipping: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/shipping/',
  vepsUrl: 'https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/payment',
  pieUrl: 'https://pie.uat.verizon.com/pie/v1',
  kafkaPageLogs: 'https://api-uat.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getMonUrl: 'https://api-uat.verizon.com/atomapi/v1/masterordernumber/order/accessoriesmon',
  orderSubmit: 'https://api-uat.verizon.com/atomapi/v1/order/order/submit',
  kafkaErrorAppLogs: 'https://api-uat.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  cwcorderassessment: 'https://api-uat.verizon.com/atomapi/v1/creditcheckservice/creditcheck/cwcorderassessment',
  //"profileInfo":"https://api-dev.ebiz.verizon.com/atomapi/v1/accessoriesqualification/customer/profile",
  cardinalURL: 'https://includestest.ccdc02.com/cardinalcruise/v1/songbird.js',
  jwtToken: 'https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/getjwttoken',
  vepsLookup: 'https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/payment',
  getCart: 'https://api-uat.verizon.com/atomapi/v1/cart/cart/',
  getTaxUrl: 'https://api-uat.verizon.com/atomapi/v1/soc/tax/getTax',
  updateIntent: 'https://api-uat.verizon.com/atomapi/v1/cart/cart/accordion/updateintent',
  shoppingCartContentUrl: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/shoppingCart/',
  beginCheckoutContent: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/guestCheckout/',
  faqUrl: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/faqs/',
  headerContentUrl: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/toolBarShippingItem/',
  standardErrors: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/standardErrors/',
  orderSubmissionPageContent: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/orderSubmissionPageContent/',
  checkoutPageContent: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkoutPageContent/',
  searchUrl: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/getAccessoriesProducts?catId=cat80001',
  vtpErrors: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/vtpErrors/',
  updateVisitDetails: 'https://api-uat.verizon.com/atomapi/v1/sessionmanager/updatevisitdetails',
  jsNotify: 'https://api-uat.verizon.com/atomapi/v1/common/logger/logmsg',
  checkUserCreditCardInfo: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkUserCreditCardInfo/',
  oneTimeRedemption: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/oneTimeRedemption/',
  applyVvcUrl:'https://www.verizon.com/solutions-and-services/verizon-visa-card/?source=fiosvvc',
  vvcRewardsUrl:'https://www.verizon.com/support/verizon-visa-card-rewards-legal/'
};
// const stageApigee = {
//   "tokenGeneration": "/home/accessories/generatetoken",
//   "createVisitId": "/home/accessories/generatevisitid",
//   "categoriesUrl":"https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/categories/",
//   "featuredAccessoriesUrl":"https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/featuredAccessories/",
//   "bannerUrl":"https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/banner/",
//   "productsUrl":"https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/products/",
//   "productDetails":"https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/productDetails/",
//   "updateCart":"https://api-uat.verizon.com/atomapi/v1/cart/cart/accordion/updatecart",
//   "myVzSignIn": "https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&mode=i&clientId=Y&customerType=DE&hideRegistration=Y&fromVZT=Y&hideTitle=Y&userNameOnly=false&goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwawssit.ebiz.verizon.com%252Fhome%252Faccessories%252Fscheckout%252Fshipping.jsp",
//   "profileInfo":"https://atomserviceswestsit.ebiz.verizon.com/v05g/sit/accessoriesqualification/customer/profile",
//   "validateName": "https://api-uat.verizon.com/atomapi/v1/contactinfo/order/validateName",
//   "validateContactInfo": "https://api-uat.verizon.com/atomapi/v1/contactinfo/order/contact",
//   "validateBillingAdress": "https://api-uat.verizon.com/atomapi/v1/billing/order/billing",
//   "shipping":"https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/shipping/",
//   "vepsUrl": "https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/payment",
//   "pieUrl": "https://pie.uat.verizon.com/pie/v1",
//   "kafkaPageLogs": "https://api-uat.verizon.com/atomapi/v1/common/kafka/atom_pagelogs",
//   "getMonUrl":"https://api-uat.verizon.com/atomapi/v1/masterordernumber/order/accessoriesmon",
//   "orderSubmit" : "https://api-uat.verizon.com/atomapi/v1/order/order/submit",
//   //"profileInfo":"https://api-uat.verizon.com/atomapi/v1/accessoriesqualification/customer/profile",
//   "cardinalURL":"https://includestest.ccdc02.com/cardinalcruise/v1/songbird.js",
//   "jwtToken":"https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/getjwttoken",
//   "vepsLookup":"https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/payment",
//   "getCart": "https://api-uat.verizon.com/atomapi/v1/cart/cart/",
//   "getTaxUrl":"https://api-uat.ebiz.verizon.com/atomapi/v1/soc/tax/getTax",
//   "jsNotify":"https://api-uat.ebiz.verizon.com/atomapi/v1/common/logger/logmsg"
// };
const stageSsoApigee = {
  tokenGeneration: '/home/accessories/generatetoken',
  createVisitId: '/home/accessories/generatevisitid',
  cacheRefresh: '/home/accessories/clearcache',
  categoriesUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/categories/',
  featuredAccessoriesUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/featuredAccessories/',
  bannerUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/banner/',
  productsUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/products/',
  productDetails: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/productDetails/',
  updateCart: 'https://api-uat.verizon.com/atomapi/v1/cart/cart/accordion/updatecart',
  //"myVzSignIn": "https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&mode=i&clientId=Y&customerType=DE&hideRegistration=Y&fromVZT=Y&hideTitle=Y&userNameOnly=false&goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwawssit.ebiz.verizon.com%252Fhome%252Faccessories%252Fscheckout%252Fshipping.jsp",
  myVzSignIn:
    'https://pprd-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www98.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fwww98.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fsso-stg-east-yz.ebiz.verizon.com%252Fhome%252Faccessories%252Fs%252Fcheckout',
  profileInfo: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/customer/profile',
  validateName: 'https://api-uat.verizon.com/atomapi/v1/contactinfo/order/validateName',
  validateContactInfo: 'https://api-uat.verizon.com/atomapi/v1/contactinfo/order/contact',
  validateBillingAdress: 'https://api-uat.verizon.com/atomapi/v1/billing/order/billing',
  shipping: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/shipping/',
  vepsUrl: 'https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/payment',
  pieUrl: 'https://pie.uat.verizon.com/pie/v1',
  kafkaPageLogs: 'https://api-uat.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getMonUrl: 'https://api-uat.verizon.com/atomapi/v1/masterordernumber/order/accessoriesmon',
  orderSubmit: 'https://api-uat.verizon.com/atomapi/v1/order/order/submit',
  kafkaErrorAppLogs: 'https://api-uat.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  cwcorderassessment: 'https://api-uat.verizon.com/atomapi/v1/creditcheckservice/creditcheck/cwcorderassessment',
  //"profileInfo":"https://api-uat.verizon.com/atomapi/v1/accessoriesqualification/customer/profile",
  cardinalURL: 'https://includestest.ccdc02.com/cardinalcruise/v1/songbird.js',
  jwtToken: 'https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/getjwttoken',
  vepsLookup: 'https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/payment',
  getCart: 'https://api-uat.verizon.com/atomapi/v1/cart/cart/',
  getTaxUrl: 'https://api-uat.verizon.com/atomapi/v1/soc/tax/getTax',
  updateIntent: 'https://api-uat.verizon.com/atomapi/v1/cart/cart/accordion/updateintent',
  shoppingCartContentUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/shoppingCart/',
  beginCheckoutContent: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/guestCheckout/',
  faqUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/faqs/',
  headerContentUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/toolBarShippingItem/',
  standardErrors: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/standardErrors/',
  orderSubmissionPageContent: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/orderSubmissionPageContent/',
  checkoutPageContent: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/checkoutPageContent/',
  searchUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/getAccessoriesProducts?catId=cat80001',
  vtpErrors: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/vtpErrors/',
  updateVisitDetails: 'https://api-uat.verizon.com/atomapi/v1/sessionmanager/updatevisitdetails',
  jsNotify: 'https://api-uat.verizon.com/atomapi/v1/common/logger/logmsg',
  checkUserCreditCardInfo: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkUserCreditCardInfo/',
  oneTimeRedemption: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/oneTimeRedemption/'
};
const stageStgApigee = {
  tokenGeneration: '/home/accessories/generatetoken',
  createVisitId: '/home/accessories/generatevisitid',
  cacheRefresh: '/home/accessories/clearcache',
  categoriesUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/categories/',
  featuredAccessoriesUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/featuredAccessories/',
  bannerUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/banner/',
  productsUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/products/',
  productDetails: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/productDetails/',
  updateCart: 'https://api-uat.verizon.com/atomapi/v1/cart/cart/accordion/updatecart',
  //"myVzSignIn": "https://sit-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&mode=i&clientId=Y&customerType=DE&hideRegistration=Y&fromVZT=Y&hideTitle=Y&userNameOnly=false&goto=https%3a%2f%2fsso-np.ebiz.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwwwawssit.ebiz.verizon.com%252Fhome%252Faccessories%252Fscheckout%252Fshipping.jsp",
  myVzSignIn:
    'https://pprd-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www98.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fwww98.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fsso-stg-east.r53np.verizon.com%252Fhome%252Faccessories%252Fs%252Fcheckout',
  profileInfo: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/customer/profile',
  validateName: 'https://api-uat.verizon.com/atomapi/v1/contactinfo/order/validateName',
  validateContactInfo: 'https://api-uat.verizon.com/atomapi/v1/contactinfo/order/contact',
  validateBillingAdress: 'https://api-uat.verizon.com/atomapi/v1/billing/order/billing',
  shipping: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/shipping/',
  vepsUrl: 'https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/payment',
  pieUrl: 'https://pie.uat.verizon.com/pie/v1',
  kafkaPageLogs: 'https://api-uat.verizon.com/atomapi/v1/common/kafka/atom_pagelogs',
  getMonUrl: 'https://api-uat.verizon.com/atomapi/v1/masterordernumber/order/accessoriesmon',
  orderSubmit: 'https://api-uat.verizon.com/atomapi/v1/order/order/submit',
  kafkaErrorAppLogs: 'https://api-uat.verizon.com/atomapi/v1/common/kafka/atom_applogs',
  cwcorderassessment: 'https://api-uat.verizon.com/atomapi/v1/creditcheckservice/creditcheck/cwcorderassessment',
  //"profileInfo":"https://api-uat.verizon.com/atomapi/v1/accessoriesqualification/customer/profile",
  cardinalURL: 'https://includestest.ccdc02.com/cardinalcruise/v1/songbird.js',
  jwtToken: 'https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/getjwttoken',
  vepsLookup: 'https://api-uat.verizon.com/atomapi/v1/veps/vtpveps/payment',
  getCart: 'https://api-uat.verizon.com/atomapi/v1/cart/cart/',
  getTaxUrl: 'https://api-uat.verizon.com/atomapi/v1/soc/tax/getTax',
  updateIntent: 'https://api-uat.verizon.com/atomapi/v1/cart/cart/accordion/updateintent',
  shoppingCartContentUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/shoppingCart/',
  beginCheckoutContent: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/guestCheckout/',
  faqUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/faqs/',
  standardErrors: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/standardErrors/',
  orderSubmissionPageContent: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/orderSubmissionPageContent/',
  checkoutPageContent: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/checkoutPageContent/',
  searchUrl: 'https://api-uat.verizon.com/atomapi/v1/qualifiedproducts/accessories/getAccessoriesProducts?catId=cat80001',
  vtpErrors: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/vtpErrors/',
  updateVisitDetails: 'https://api-uat.verizon.com/atomapi/v1/sessionmanager/updatevisitdetails',
  jsNotify: 'https://api-uat.verizon.com/atomapi/v1/common/logger/logmsg',
  checkUserCreditCardInfo: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/checkUserCreditCardInfo/',
  oneTimeRedemption: 'https://api-uat.verizon.com/atomapi/v1/accessoriesqualificationservice/accessories/oneTimeRedemption/'
};

/*export const getVzDomainUrl = () => {
  var envName = window.location.host;
  switch (envName) {
    case ATOM_CONST.SITURL:
      return "https://wwwawssit.ebiz.verizon.com";
    case ATOM_CONST.UATURL:
      return "https://wwwawsuat.ebiz.verizon.com";
    case ATOM_CONST.STAGEACCESSORIESURL:
      return "https://www98v05g-vtp-react-east-yz.ebiz.verizon.com";
    case ATOM_CONST.STAGEURL:
      return "https://www98.verizon.com";
    case ATOM_CONST.STAGEEASTURL:
      return "https://www98v05g-vtp-react-east-yz.ebiz.verizon.com";
    case ATOM_CONST.STAGEWESTURL:
      return "https://www98v05g-vtp-react-west-yz.ebiz.verizon.com";
    case ATOM_CONST.NTE1URL:
      return "https://wwwnte1aws.ebiz.verizon.com";
    case ATOM_CONST.NTE3URL:
      return "https://wwwnte3aws.ebiz.verizon.com";
    case ATOM_CONST.NTE4URL:
      return "https://wwwnte4aws.ebiz.verizon.com";
    case ATOM_CONST.NTE5URL:
      return "https://wwwnte5aws.ebiz.verizon.com";
    case ATOM_CONST.PILOTURL:
      return "https://orderinglite.verizon.com";
    case ATOM_CONST.PILOTURLE:
      return "https://orderinglite-gz-east.vpc.verizon.com";
    case ATOM_CONST.PREPROD:
      return "https://www.preprod.verizon.com";
    case ATOM_CONST.PREPRODEAST:
      return "https://preprod-east-gz.vpc.verizon.com";
    case ATOM_CONST.PRODEASTURL:
      return "https://wwwordering-prod-east-yz.vpc.verizon.com";
    case ATOM_CONST.PRODGSLBURL:
      return "https://wwwordering-gslb-prod-yz.vpc.verizon.com";
    case ATOM_CONST.PRODSSOERURL:
      return "https://sso-prod-east.r53pr.verizon.com";
    case ATOM_CONST.PRODSSOEURL:
      return "https://sso-pr-east-yz.vpc.verizon.com";
    case ATOM_CONST.PRODSSOWRURL:
      return "https://sso-prod-west.r53pr.verizon.com";
    case ATOM_CONST.PRODSSOWURL:
      return "https://sso-pr-west-yz.vpc.verizon.com";
    case ATOM_CONST.PRODWESTURL:
      return "https://wwwordering-prod-west-yz.vpc.verizon.com";
    case ATOM_CONST.LOCALURL:
      return "http://localhost:3000";
    default:
      return "https://www.verizon.com";
  }
};
*/
const ATOM_CONST = {
  'www.verizon.com': 'PROD',
  'wwwv05g-vtp-east-yz.vpc.verizon.com': 'PROD',
  'wwwv05g-vtp-west-yz.vpc.verizon.com': 'PROD',
  'wwwvtp-prod-gslb-yz.vpc.verizon.com': 'PROD',
  'sso-prod-east.r53pr.verizon.com': 'PROD',
  'sso-prod-west.r53pr.verizon.com': 'PROD',
  'sso-pr-east-yz.vpc.verizon.com': 'PROD',
  'sso-pr-west-yz.vpc.verizon.com': 'PROD',
  'www98.verizon.com': 'STAGE',
  'www98v05g-vtp-react-east-yz.ebiz.verizon.com': 'STAGE',
  'sso-stg-east-yz.ebiz.verizon.com': 'STAGESSO',
  'sso-stg-west-yz.ebiz.verizon.com': 'STAGE',
  'sso-stg-east.r53np.verizon.com': 'STAGESTG',
  'sso-stg-west.r53np.verizon.com': 'STAGE',
  'orderinglite.verizon.com': 'PILOT',
  'orderinglite-gz-east.vpc.verizon.com': 'PILOT',
  'preprod-east-gz.vpc.verizon.com': 'PREPROD',
  'www.preprod.verizon.com': 'PREPROD',
  'wwwawssit.ebiz.verizon.com': 'SIT',
  'wwwawsuat.ebiz.verizon.com': 'UAT',
  'wwwnte1aws.ebiz.verizon.com': 'NTE1',
  'wwwnte3aws.ebiz.verizon.com': 'NTE3',
  'wwwnte4aws.ebiz.verizon.com': 'NTE4',
  'wwwnte5aws.ebiz.verizon.com': 'NTE5',
  'espanol.verizon.com': 'PROD',
  'localhost:3000': 'LOCAL',
  'localhost.verizon.com:3000': 'LOCALAPIGEE',
};

const getEnvName = () => {
  const host = window.location.host;
  return ATOM_CONST[host];
};

const env = getEnvName();
let apiURLConfig = null;

const proxyURL = {
  SIT: [sitApigee, nte1Proxy],
  //SIT: [nte1Apigee, nte1Proxy],
  // UAT: [uatApigee, uatProxy],
  STAGE: [stageApigee, nte1Proxy],
  STAGESSO: [stageSsoApigee, nte1Proxy],
  STAGESTG: [stageStgApigee, nte1Proxy],
  // PILOT: [pilotApigee, pilotProxy],
  PREPROD: [preProdApigee, nte1Proxy],
  PROD: [prodApigee, nte1Proxy],
  NTE1: [nte1Apigee, nte1Proxy],
  // NTE3: [nte3Apigee, nte3Proxy],
  // NTE4: [nte4Apigee, nte4Proxy],
  // NTE5: [uatApigee, uatProxy],
  LOCAL: [nte1Apigee, sitProxy],
  LOCALAPIGEE: [nte1Apigee, nte1Proxy],
};

export const apiUrl = () => {
  apiURLConfig || (apiURLConfig = getEnvUrlObj());
  return apiURLConfig;
};

const getEnvUrlObj = () => {
  // let isProxyURL = false;
  let isProxyURL = env === 'LOCAL';
  let envConfigURL = null;
  const urlEnv = proxyURL[env] || proxyURL['LOCAL'];
  envConfigURL = urlEnv[+isProxyURL];
  if (envConfigURL.createVisitId.indexOf('/') === 0) {
    envConfigURL.createVisitId = window.location.origin + envConfigURL.createVisitId;
    envConfigURL.tokenGeneration = window.location.origin + envConfigURL.tokenGeneration;
  }
  // if(process.env.NODE_ENV === 'development' && envConfigURL.createVisitId.indexOf("/")===0)
  // {
  //   const baseURL = window.location.origin.replace('3000', '8080');//.replace('https', 'http') ;
  //     envConfigURL.createVisitId= baseURL + envConfigURL.createVisitId.slice(7);
  //     envConfigURL.tokenGeneration= baseURL + envConfigURL.tokenGeneration.slice(7);
  // } //localDev
  return envConfigURL;
};

const DOMAINS = {
  PROD: 'www.verizon.com',
  PILOT: 'orderinglite-gz-east.vpc.verizon.com',
  PREPROD: 'www.preprod.verizon.com',
  PREPRODEAST: 'preprod-east-gz.vpc.verizon.com',
  // STAGE: "www98.verizon.com",
  STAGE: 'www98v05g-vtp-react-east-yz.ebiz.verizon.com',
  SIT: 'wwwawssit.ebiz.verizon.com',
  UAT: 'wwwawsuat.ebiz.verizon.com',
  NTE1: 'wwwnte1aws.ebiz.verizon.com',
  NTE3: 'wwwnte3aws.ebiz.verizon.com',
  NTE4: 'wwwnte4aws.ebiz.verizon.com',
  NTE5: 'wwwnte5aws.ebiz.verizon.com',
  ESPANOL: 'espanol.verizon.com',
};
/*export const getGlobalSessionName = () => {
  let hostName = window.location.host;
  let globalName = '';
  if (hostName.indexOf(DOMAINS.SIT) > -1) {
    globalName = "GlobalSessionID_awssit";
  }
  else if (hostName.indexOf(DOMAINS.UAT) > -1) {
    globalName = "GlobalSessionID_awsuat";
  }
  else if (hostName.indexOf(DOMAINS.NTE1) > -1) {
    globalName = "GlobalSessionID_awsnte1";
  }
  else if (hostName.indexOf(DOMAINS.NTE3) > -1) {
    globalName = "GlobalSessionID_awsnte3";
  }
  else if (hostName.indexOf(DOMAINS.NTE4) > -1) {
    globalName = "GlobalSessionID_awsnte4";
  }
  else if (hostName.indexOf(DOMAINS.STAGE) > -1) {
    globalName = "GlobalSessionID_98";
  }
  else if (hostName.indexOf(DOMAINS.PILOT) > -1) {
    globalName = "GlobalSessionID";
  }
  else if (hostName.indexOf(DOMAINS.PROD) !== -1) {
    globalName = "GlobalSessionID";
  }
  else {
    globalName = "GlobalSessionID_awssit";
  }
  return globalName;
}*/