import React from 'react';
//import { logOtherInfo,detailsInfo } from './logService'

class ErrorBoundary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: '', errorInfo: ''};
      }

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true, error, errorInfo});

      //console.log(this.props.appState.visitId);


      //catchJSException('Checkout','12345678','componentWillMount',errorInfo);
      //logOtherInfo(detailsInfo('Checkout','12345678','componentWillMount',errorInfo,"JSERROR"));

    }

    render()
    {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (<>
              <h1>{this.state.error.message}</h1>
              <div>{this.state.error.stack}</div>
              <div>{this.state.errorInfo.componentStack}</div>
            </>)
          }
          return this.props.children;
    }
  }

  export default ErrorBoundary