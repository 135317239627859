const initialState = {
  personalInfo: {
    firstName: '',
    firstNameError: '',
    lastName: '',
    lastNameError: '',
    eMail: '',
    eMailError: '',
    isValidMail: false,
    phoneNumber: '',
    phoneNumberError: '',
    address: '',
    addressError: '',
    address2: '',
    city: '',
    cityError: '',
    state: '',
    stateError: '',
    zipcode: '',
    zipcodeError: '',
    shippingOpt: '',
    phoneNumberText: '',
  },
  billingInfo: {
    firstName: '',
    firstNameError: '',
    lastName: '',
    lastNameError: '',
    cardNumber: '',
    securityCode: '',
    cardType: '',
    cardTypeError: '',
    cardDetail: '',
    cardNumberError: '',
    cardSecurityCode: '',
    cardSecurityCodeError: '',
    formattedCard: '',
    month: '',
    monthError: '',
    year: '',
    yearError: '',
  },
  couponInfo: {
    couponCode: '',
    couponError: '',
  },
  noOfOTPAttemps: 0,
  checkoutContent: '',
  noOfPlzceOrderAttemps: 0,
  isValidBillingAddress: false,
  isValidCard: false,
  profileInfo: '',
  shippingMethod: {
    options: '',
    selectedProd: '',
  },
  isSignInSuccessful: false,
  isEditShippingInfo: false,
  isEditAddress: false,
  isBillingSection: false,
  isEditBillingInfo: false,
  isPartialSignIn: false,
  isEditPartialSignIn: false,
  isOrderPlaced: false,
  isExpressCheckout: true,
  isNonExpressCheckoutStatus: 'completed',
  isBtaOption: true,
  showPlaceOrderBtn: true,
  showErrorMsg: false,
  showMaxAttemptErrorMsg: false,
  showLecOnlyErrorMsg: false,
  showPOErrorMsg: false,
  disableCustomer: false,
  threeDSCounter: 0,
  safeTechCounter: 0,
  showThreeDSMaxAttemptErrorMsg: false,
  showVepsMaxAttemptErrorMsg: false,
  showTDSError: false,
};
function checkout(state = initialState, action) {
  switch (action.type) {
    case 'SHIPPINGINFOSTATUS':
      return Object.assign({}, state, {
        shippingInfoStatus: action.value,
      });
    case 'BILLINGINFOSTATUS':
      return Object.assign({}, state, {
        billingInfoStatus: action.value,
      });
    case 'ADDPERSONALINFO':
      let details = Object.assign({}, state.personalInfo, {
        firstName:
          action.value.firstName !== undefined && action.value.firstName !== state.personalInfo.firstName
            ? action.value.firstName
            : state.personalInfo.firstName,
        lastName:
          action.value.lastName !== undefined && action.value.lastName !== state.personalInfo.lastName
            ? action.value.lastName
            : state.personalInfo.lastName,
        firstNameError:
          action.value.firstNameError !== undefined && action.value.firstNameError !== state.personalInfo.firstNameError
            ? action.value.firstNameError
            : state.personalInfo.firstNameError,
        lastNameError:
          action.value.lastNameError !== undefined && action.value.lastNameError !== state.personalInfo.lastNameError
            ? action.value.lastNameError
            : state.personalInfo.lastNameError,
        eMail: action.value.eMail !== undefined && action.value.eMail !== state.personalInfo.eMail ? action.value.eMail : state.personalInfo.eMail,
        eMailError:
          action.value.eMailError !== undefined && action.value.eMailError !== state.personalInfo.eMailError
            ? action.value.eMailError
            : state.personalInfo.eMailError,
        isValidMail:
          action.value.isValidMail !== undefined && action.value.isValidMail !== state.personalInfo.isValidMail
            ? action.value.isValidMail
            : state.personalInfo.isValidMail,
        phoneNumber:
          action.value.phoneNumber !== undefined && action.value.phoneNumber !== state.personalInfo.phoneNumber
            ? action.value.phoneNumber
            : state.personalInfo.phoneNumber,
        phoneNumberError:
          action.value.phoneNumberError !== undefined && action.value.phoneNumberError !== state.personalInfo.phoneNumberError
            ? action.value.phoneNumberError
            : state.personalInfo.phoneNumberError,
        address:
          action.value.address !== undefined && action.value.address !== state.personalInfo.address
            ? action.value.address
            : state.personalInfo.address,
        address2:
          action.value.address2 !== undefined && action.value.address2 !== state.personalInfo.address2
            ? action.value.address2
            : state.personalInfo.address2,
        addressError:
          action.value.addressError !== undefined && action.value.addressError !== state.personalInfo.addressError
            ? action.value.addressError
            : state.personalInfo.addressError,
        city: action.value.city !== undefined && action.value.city !== state.personalInfo.city ? action.value.city : state.personalInfo.city,
        cityError:
          action.value.cityError !== undefined && action.value.cityError !== state.personalInfo.cityError
            ? action.value.cityError
            : state.personalInfo.cityError,
        state: action.value.state !== undefined && action.value.state !== state.personalInfo.state ? action.value.state : state.personalInfo.state,
        stateError:
          action.value.stateError !== undefined && action.value.stateError !== state.personalInfo.stateError
            ? action.value.stateError
            : state.personalInfo.stateError,
        zipcode:
          action.value.zipcode !== undefined && action.value.zipcode !== state.personalInfo.zipcode
            ? action.value.zipcode
            : state.personalInfo.zipcode,
        zipcodeError:
          action.value.zipcodeError !== undefined && action.value.zipcodeError !== state.personalInfo.zipcodeError
            ? action.value.zipcodeError
            : state.personalInfo.zipcodeError,
        shippingOpt:
          action.value.shippingOpt !== undefined && action.value.shippingOpt !== state.personalInfo.shippingOpt
            ? action.value.shippingOpt
            : state.personalInfo.shippingOpt,
        phoneNumberText:
          action.value.phoneNumberText !== undefined && action.value.phoneNumberText !== state.personalInfo.phoneNumberText
            ? action.value.phoneNumberText
            : state.personalInfo.phoneNumberText,
      });
      return Object.assign({}, state, {
        personalInfo: details,
      });
    case 'ADDBILLINGINFO':
      let billingData = Object.assign({}, state.billingInfo, {
        firstName:
          action.value.firstName !== undefined && action.value.firstName !== state.billingInfo.firstName
            ? action.value.firstName
            : state.billingInfo.firstName,
        lastName:
          action.value.lastName !== undefined && action.value.lastName !== state.billingInfo.lastName
            ? action.value.lastName
            : state.billingInfo.lastName,
        firstNameError:
          action.value.firstNameError !== undefined && action.value.firstNameError !== state.billingInfo.firstNameError
            ? action.value.firstNameError
            : state.billingInfo.firstNameError,
        lastNameError:
          action.value.lastNameError !== undefined && action.value.lastNameError !== state.billingInfo.lastNameError
            ? action.value.lastNameError
            : state.billingInfo.lastNameError,
        cardType:
          action.value.cardType !== undefined && action.value.cardType !== state.billingInfo.cardType
            ? action.value.cardType
            : state.billingInfo.cardType,
        cardNumber:
          action.value.cardNumber !== undefined && action.value.cardNumber !== state.billingInfo.cardNumber
            ? action.value.cardNumber
            : state.billingInfo.cardNumber,
        securityCode:
          action.value.securityCode !== undefined && action.value.securityCode !== state.billingInfo.securityCode
            ? action.value.securityCode
            : state.billingInfo.securityCode,

        cardTypeError:
          action.value.cardTypeError !== undefined && action.value.cardTypeError !== state.billingInfo.cardTypeError
            ? action.value.cardTypeError
            : state.billingInfo.cardTypeError,
        cardDetail:
          action.value.cardDetail !== undefined && action.value.cardDetail !== state.billingInfo.cardDetail
            ? action.value.cardDetail
            : state.billingInfo.cardDetail,
        cardNumberError:
          action.value.cardNumberError !== undefined && action.value.cardNumberError !== state.billingInfo.cardNumberError
            ? action.value.cardNumberError
            : state.billingInfo.cardNumberError,
        cardSecurityCode:
          action.value.cardSecurityCode !== undefined && action.value.cardSecurityCode !== state.billingInfo.cardSecurityCode
            ? action.value.cardSecurityCode
            : state.billingInfo.cardSecurityCode,
        cardSecurityCodeError:
          action.value.cardSecurityCodeError !== undefined && action.value.cardSecurityCodeError !== state.billingInfo.cardSecurityCodeError
            ? action.value.cardSecurityCodeError
            : state.billingInfo.cardSecurityCodeError,
        formattedCard:
          action.value.formattedCard !== undefined && action.value.formattedCard !== state.billingInfo.formattedCard
            ? action.value.formattedCard
            : state.billingInfo.formattedCard,
        month: action.value.month !== undefined && action.value.month !== state.billingInfo.month ? action.value.month : state.billingInfo.month,
        monthError:
          action.value.monthError !== undefined && action.value.monthError !== state.billingInfo.monthError
            ? action.value.monthError
            : state.billingInfo.monthError,
        year: action.value.year !== undefined && action.value.year !== state.billingInfo.year ? action.value.year : state.billingInfo.year,
        yearError:
          action.value.yearError !== undefined && action.value.yearError !== state.billingInfo.yearError
            ? action.value.yearError
            : state.billingInfo.yearError,
      });
      return Object.assign({}, state, {
        billingInfo: billingData,
      });
    case 'CHECKOUTCONTENTFROMAPI':
      return Object.assign({}, state, {
        checkoutContent: action.value,
      });
    case 'UPDATEOTPATTEMPTS':
      return Object.assign({}, state, {
        noOfOTPAttemps: action.value,
      });
    case 'UPDATEBILINGADDRESSSTATUS':
      return Object.assign({}, state, {
        isValidBillingAddress: action.value,
      });
    case 'UPDATEPAYMENTSTATUS':
      return Object.assign({}, state, {
        isValidCard: action.value,
      });
    case 'UPDATEPROFILEINFO':
      return Object.assign({}, state, {
        profileInfo: action.value,
      });
    case 'UPDATESHIPPINGINFOSTATUS':
      return Object.assign({}, state, {
        isEditShippingInfo: action.value,
      });
    case 'UPDATEBILLINGINFOSTATUS':
      return Object.assign({}, state, {
        isEditBillingInfo: action.value,
      });
    case 'UPDATESIGNINSTATUS':
      return Object.assign({}, state, {
        isSignInSuccessful: action.value,
      });
    case 'UPDATEPARTIALSIGNIN':
      return Object.assign({}, state, {
        isPartialSignIn: action.value,
      });
    case 'EDITPARTIALSIGNIN':
      return Object.assign({}, state, {
        isEditPartialSignIn: action.value,
      });
    case 'UPDATECHANGEADDRESSSTATUS':
      return Object.assign({}, state, {
        isEditAddress: action.value,
      });
    case 'UPDATEBILLINGSECTION':
      return Object.assign({}, state, {
        isBillingSection: action.value,
      });
    case 'UPDATEEXPRESSCHECKOUT':
      return Object.assign({}, state, {
        isExpressCheckout: action.value,
      });
    case 'NONEXPRESSCHECKOUTSTATUS':
      return Object.assign({}, state, {
        isNonExpressCheckoutStatus: action.value,
      });
    case 'SHOWBTAOPTION':
      return Object.assign({}, state, {
        isBtaOption: action.value,
      });
    case 'UPDATECUSTOMERACTION':
      return Object.assign({}, state, {
        disableCustomer: action.value,
      });
    case 'SHOWPLACEORDER':
      return Object.assign({}, state, {
        showPlaceOrderBtn: action.value,
      });
    case 'SHOWPLACEORDERERRORMSG':
      return Object.assign({}, state, {
        showErrorMsg: action.value,
      });
    case 'SHOWMAXATTEMPTSERRORMSG':
      return Object.assign({}, state, {
        showMaxAttemptErrorMsg: action.value,
      });
    case 'SHOWLECONLYERRORMSG':
      return Object.assign({}, state, {
        showLecOnlyErrorMsg: action.value,
      });
    case 'SHOWPOERRORMSG':
      return Object.assign({}, state, {
        showPOErrorMsg: action.value,
      });
    case 'UPDATEPLACEORDERATTEMPTS':
      return Object.assign({}, state, {
        noOfPlzceOrderAttemps: action.value,
      });
    case 'SHIPPINGMETHOD':
      let shippingOption = Object.assign({}, state.shippingMethod, {
        options:
          action.value.options !== undefined && action.value.options !== state.shippingMethod.options
            ? action.value.options
            : state.shippingMethod.options,
        selectedProd:
          action.value.selectedProd !== undefined && action.value.selectedProd !== state.shippingMethod.selectedProd
            ? action.value.selectedProd
            : state.shippingMethod.selectedProd,
      });
      return Object.assign({}, state, {
        shippingMethod: shippingOption,
      });
    case 'MONFROMAPI': {
      return { ...state, monValue: action.value };
    }
    case 'MONINVOKEDFROMAPI': {
      return { ...state, monInvoked: action.value };
    }
    case 'PLACEORDER': {
      return { ...state, isOrderPlaced: action.value };
    }
    case 'JWTTOKEN': {
      return { ...state, jwtToken: action.value };
    }
    case 'VEPSRESPONSE': {
      return { ...state, vepsResponse: action.value };
    }
    case 'TAXFROMAPI': {
      return { ...state, taxAmount: action.value };
    }
    case 'ADDCOUPONINFO': {
      return { ...state, couponInfo: action.value };
    }
    case 'UPDATETHREEDSCOUNTER': {
      return { ...state, threeDSCounter: action.value };
    }
    case 'UPDATESAFETECHCOUNTER': {
      return { ...state, safeTechCounter: action.value };
    }
    case 'SHOWTHREEDSMAXATTEMPTSERRORMSG': {
      return { ...state, showThreeDSMaxAttemptErrorMsg: action.value };
    }
    case 'SHOWVEPSMAXATTEMPTSERRORMSG': {
      return { ...state, showVepsMaxAttemptErrorMsg: action.value };
    }
    case 'UPDATETHREEDSEMVERROR': {
      return { ...state, showTDSError: action.value };
    }
    default:
      return state;
  }
}
export default checkout;
