//import { LOCATION_CHANGE } from "connected-react-router";
//import { Route } from "react-router";

//import {productsAction}  from '../action/productsAction';
const initialState = {};
function products(state = initialState, action) {
  switch (action.type) {
    case 'PRODUCTSFROMJSON': {
      //      Object.assign( state, {
      //         productsExp: action.value
      //     })
      // return state;
      return { ...state, productsExp: action.value };
    }
    case 'FILTERFROMJSON': {
      //     Object.assign( state, {
      //        filterExp: action.value
      //    })
      //      return state;
      return { ...state, filterExp: action.value };
    }
    case 'PRODUCTSFROMAPI': {
      //     Object.assign( state, {
      //        productsExp: action.value
      //    })
      //      return state;
      return { ...state, productsExp: action.value };
    }
    case 'SELECTEDPROD': {
      //     Object.assign( state, {
      //        selectedProduct: action.value
      //    })
      //         return state;
      return { ...state, selectedProduct: action.value };
    }
    case 'SETFILTERDATA': {
      return { ...state, filterData: action.value };
    }
    case 'CLEARFILTERDATA': {
      return { ...state, filterData: null };
    }
    case 'PRODUCTBANNER': {
      return { ...state, prodBanner: action.value };
    }

    // case LOCATION_CHANGE:{
    //    if (window.performance) {
    //     if (performance.navigation.type == 1) {
    //       return{...state}
    //     }
    //     else {
    //       return{}
    //     }
    //   }
    // }

    case 'STOREPRODUCTID': {
      // Object.assign( state, {
      //     selectedCategoryAndProduct: action.value,
      // })
      // return state;
      return { ...state, selectedCategoryAndProduct: action.value };
    }
    case 'STORECOLORCLICK': {
      return { ...state, storeColorClick: action.value };
    }

    case 'PAGEINFO': {
      return { ...state, pageinfo: action.value };
    }
    case 'RAWRESPONSE': {
      //     Object.assign( state, {
      //        productsRawResponse: action.value
      //    })
      //      return state;
      return { ...state, productsRawResponse: action.value };
    }
    case 'PAGINATION': {
      return { ...state, paginationExp: action.value };
    }
    case 'SELECTEDCATEGORYDESC': {
      return { ...state, selectedCategoryDescExp: action.value };
    }
    case 'BANNERSTATUS': {
      return { ...state, bannerCallStatus: action.value };
    }
    case 'FILTERFLAG': {
      return { ...state, filterFlag: action.value };
    }
    default:
      return state;
  }
}
export default products;
