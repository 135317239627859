/*!
* VZ Native App Interface Library
* Date: Jan 3 2013
*/
import $ from 'jquery';

// dynamic JS URI
var VZWebLib_FYH_URL = "https://signin.verizon.com/sso/ProcessLoginServlet?target=https%3A%2F%2Fwww25.verizon.com%2Fforyourhome%2Fmyaccount%2Fngen%2Fpr%2Fcommon%2Feprocess.aspx%3FSrcAppID%3DVMAPP%26Target%3D";
var VZWebLib_FYH_COOKIE_EXPIRY_MINS = 30;
var KEEP_ONLY_THESE_COOKIES = "dotcomsid=;foryourhome=;";
// DONT_USE_All_COOKIES = "dotcomsid;foryourhome;AprURL;AkaSTrackingID;AkaUTrackingID;RegistrationApp;VzTV;VzUpro;eup;lia;lob;logout_url;user_profile;vzapps;vzreg";

// start iOS app native interface
var VZWebLib_iOS = {
	callBackFuncCounter : 0,
	goBackHandler : function () { return 0; },
	BRIDGE_SCHEME : "myvzapp://www.verizon.com/myverizon/",
	
	//eventHandler(event, msg)
	setEventHandler : function (eventHandler) {
		$('body').unbind('_webviewactive').bind('_webviewactive', eventHandler);
		$('body').unbind('_webviewinactive').bind('_webviewinactive', eventHandler);
		$('body').unbind('_urlredirection').bind('_urlredirection', eventHandler);
		$('body').unbind('_ssosigninsuccess').bind('_ssosigninsuccess', eventHandler);
		$('body').unbind('_ssosignout').bind('_ssosignout', eventHandler);
		$('body').unbind('_ssosigninerror').bind('_ssosigninerror', eventHandler);
		$('body').unbind('_ssosignincancel').bind('_ssosignincancel', eventHandler);
		$('body').unbind('_ssorefreshsuccess').bind('_ssorefreshsuccess', eventHandler);
		$('body').unbind('_ssorefresherror').bind('_ssorefresherror', eventHandler);
		$('body').unbind('_networkconnection').bind('_networkconnection', eventHandler);
	},

	/* Set a defined handler function that will implement GoBack() */
	setGoBackHandler : function (goBackHandler) {
		this.goBackHandler = goBackHandler;
	},
	
	/* For making bridge calls to native code */
	_bridgeCall : function (bcall) {
		if(bcall) {
			var bridgeurl = this.BRIDGE_SCHEME + bcall;
			var iframe = document.createElement("IFRAME");
			iframe.style = "display:none; height:0px; width:0px;";
			iframe.src = bridgeurl;
			document.body.appendChild(iframe);
			iframe.parentNode.removeChild(iframe);
			iframe = null;
		}
	},
	
	// sample usecase:  _bridgeCallWithCallback('showspinner', function() {  //spinner done});
	_bridgeCallWithCallback : function (bridgecall_action, bridgecall_params, callbackFunction) {
		try {
		let	callback_event = "bridgecallevent"+ this.callBackFuncCounter++;
			
			$('body').unbind(callback_event).bind(callback_event, {callback: callbackFunction}, function(event, msg) { 
				event.data.callback(msg); //call handler
				$(this).unbind(event)} //unbind event
				);
			this._bridgeCall(bridgecall_action + '?' + 'callback=' + callback_event + '&' + bridgecall_params);
			
			if(this.callBackFuncCounter >= 100)
				this.callBackFuncCounter = 0;
		}
		catch (exception) { var message = (exception.message !== undefined) ? exception.message : exception;
			this.Log('uhh oh! ' + ' : ' + message);
		}
	},
	
	loadComplete : function () {
		//this.log('VZWebLib load Complete');
		this._bridgeCall("loadcomplete");
	},
		
	signIn : function () {
		this._bridgeCall('signin');
	},
	
	getSignInStatus : function (callbackFunction) {
		this._bridgeCallWithCallback('getsigninstatus', '', callbackFunction);
	},
	
	setTitle : function (title) {
		this._bridgeCall('settitle?title=' + title);
	},
	
	setBackStatus : function (canGoBack) {
		this._bridgeCall('setbackstatus?back=' + canGoBack);
	},
	
	showSpinner : function () {
		this._bridgeCall('showspinner');
	},
	
	hideSpinner : function () {
		this._bridgeCall('hidespinner');		
	},
	
	dismissWebView : function () {
		this._bridgeCall('dismisswebview');
	},
	
	killWebView : function () {
		this._bridgeCall('killwebview');
	},
	
	testDeepLink : function (url, callbackFunction) {
		this._bridgeCallWithCallback("testdeeplink", 'url=' + url, callbackFunction);
	},
	
	deepLink : function (url) {
		this._bridgeCall("deeplink?url=" + url);
	},
	
	navigatedToPage : function (toPage) {
		this._bridgeCall('navigatedtopage?page=' + toPage);
	},
	
	getDeviceInfo : function (callbackFunction) {
		this._bridgeCallWithCallback('getdeviceinfo', '', callbackFunction);
	},
	
	trackEvent : function (category, action, label, value, source, callbackFunction) {
		this._bridgeCallWithCallback('trackevent', 'category=' + category + '&action=' + action + '&label=' + label + '&value=' + '&source=' + source, callbackFunction);
	},
	
	getNetworkStatus : function (callbackFunction) {
		this._bridgeCallWithCallback('getnetworkstatus', '', callbackFunction);
	},
	
	/*
		Logging to native console
	*/
	log : function (msg) {
		this._bridgeCall('log?msg=' + msg);
	},
  	/*
		Native alert windows with optional buttons
  	*/
  	alertConfirmationWithButtons: function (title, msg, callbackFunction, cancelButton, okButton, otherButton) {
    	this._bridgeCallWithCallback('alertconfirmation', 'title=' + title + '&msg=' + msg + '&cancelbutton=' + cancelButton + '&okbutton=' + okButton + '&otherbutton=' + otherButton, callbackFunction);
  	},
    disableLoadingOtherUrl: function (disable){
    }
};

// end iOS app native interface

// start android app native interface
// rchukkapalli Jan 3 2013
var androidNativeInterface = {
  bridgeFrame: null,
  callBackFuncCounter: 0,
  BRIDGE_SCHEME: 'VZAPP://BRIDGE.CALL',
  //fetchFYHCookie: 0,
  apiVersion: 11,
  version: "1.2.1",
  _init: function () {
    if(this.bridgeFrame === null){
      $('#bridgeframe').remove();
      this.bridgeFrame = $('<iframe id="bridgeframe" style="display:none;"/>').appendTo('body')[0];
      if($('#bridgeframe').length === 0) return false;
      this.printVersion();
    }
    return true;
  },
  _genCallBackId: function(callBack, callBackObj){
    var callBackId = 'callBackFuncId' + (this.callBackFuncCounter++);
    $(this.bridgeFrame).bind(callBackId, function(e,msg){
      if(typeof callBackObj === 'undefined') callBack(msg);
      else callBack.call(callBackObj, msg);
      $(this.bridgeFrame).unbind(callBackId);
    });
    return callBackId;
  },
  _bridgeCall: function(uri, callBack, callBackObj){    
    if(this._init()){
      this.log('_bridgeCall: ' + uri + ', previous queue length:' + ($(this.bridgeFrame).queue().length));
      var that = this;
      $(this.bridgeFrame).queue(function(){
        that.bridgeFrame.src = that.BRIDGE_SCHEME + uri + 
          ((typeof callBack === 'function') ? ('&callback=' + that._genCallBackId(callBack, callBackObj)) : '');
      });
    }else{
      this.log('Critical : Bridge initialization failed, DOM might not be ready. Try again after document onready.', true);
    }
  },
  _dequeueBridgeCall: function(){
    //this.log('nextBridgeCall dequeueing...');
    $(this.bridgeFrame).dequeue();
  },
  loadComplete: function (){
    this._bridgeCall('?m=loadComplete');
  },
  signIn: function (){
    this._bridgeCall('?m=signIn&fyh=false');
  },
  _signInJS: function (fetchFYHCookies){
    this.fetchFYHCookie = ((typeof fetchFYHCookies === 'undefined' || fetchFYHCookies === false) ? 0 : 1);
    if(this.fetchFYHCookie === 1 && document.cookie.indexOf("foryourhome") >= 0){
      this.fetchFYHCookie = 0;
      this.log("FYH cookie already present.");
    }

    this._bridgeCall('?m=signIn&fyh=false');
  },
  signInWithUser: function (userId, passwd, remember){
    //var rememberme = ((typeof remember === 'undefined' || remember == false) ? 0 : 1);
    this._bridgeCall('?m=signIn&userid='+userId +'&passwd=' + passwd + '&remember=' + remember);
  },
  getSignInStatus: function (callBack, callBackObj) {
    this._bridgeCall('?m=getSignInStatus', callBack, callBackObj);
  },
  setTitle: function (title){
    this._bridgeCall('?m=setTitle&title='+ title);
  },
  dismissWebView: function (){
    this._bridgeCall('?m=dismissWebView');
  },
  showSpinner: function (message){
    this._bridgeCall('?m=showSpinner&status=true&msg='+ (typeof message === 'undefined'? "": message));
  },
  hideSpinner: function (){
    this._bridgeCall('?m=showSpinner&status=false');
  },
  alertConfirmationWithButtons: function (title, msg, callBack, cancelButton, okButton, otherButton){
    this._bridgeCall('?m=showAlert&title=' + title + '&msg=' + msg 
      + '&cancelButton=' + cancelButton + '&okButton=' + okButton + '&otherButton=' + otherButton, callBack);
  },
  deepLink: function (uri){
    this._bridgeCall('?m=deepLink&uri='+uri);
  },
  navigatedToPage: function (uri){
    this._bridgeCall('?m=navigatedToPage&uri='+uri);
  },
  getDeviceInfo: function (callBack, callBackObj){
    this._bridgeCall('?m=getDeviceInfo', callBack, callBackObj);
  },  
  getNetworkStatus: function (callBack) {
    this._bridgeCall('?m=getNetworkStatus', callBack);
  },
  trackEvent: function (category, action, label, value, source, callBack, callBackObj) {
    this._bridgeCall('?m=trackevent&category=' + category + '&action=' + action + '&label=' + label + '&value=' 
      + '&source=' + source, callBack, callBackObj);
  },
  _trigger: function (eventName, eventParamArray) {
    this.log("native2js: event trigger, name: " + eventName + ", param: " + ($.isArray(eventParamArray) ? eventParamArray.toString() : JSON.stringify(eventParamArray)));
    if(eventName === "_ssosigninsuccess" && this.fetchFYHCookie === 1){
      this._fetchFYH(eventName, eventParamArray);
    }else{
      $(this.bridgeFrame).trigger(eventName, eventParamArray);
    }
  },
  _fetchFYH: function (eventName, eventParamArray) {
    //var that = this;
  },
  __fetchFYH_OLD_DO_NOT_USE: function (eventName, eventParamArray) {
    this.showSpinner();
    var that = this;
    $('#fyhFrame').remove();
    var fyhFrame = $('<iframe id="fyhFrame" style="display:none;"/>').appendTo('body')[0];
    $(fyhFrame).load(function(){ // SLOW....
      that.log("FYH Frame Loaded: " + fyhFrame.src);
      $('#fyhFrame').remove();
      that.fetchFYHCookie = 2;
      that.hideSpinner();
      that._trigger(eventName, eventParamArray);
    });
    fyhFrame.src = VZWebLib_FYH_URL;
  },
  printVersion: function (){
    this.log("loaded android native javascript interface, android apiVersion:" + this.apiVersion + ", obj version:" + this.version);
  },
  log: function (msg, error){
    if(typeof error === 'undefined') console.info(msg);
    else console.error(msg);
     
    // if (typeof LOG === 'function') LOG(msg); // push log to html if it can handle it
  },
  _checkCanGoBackHandler: function () {
    var canGoBackStatus=false;
    if(typeof this.canGoBackHandler === 'function'){
      try{
        canGoBackStatus = this.canGoBackHandler();
      }catch(e){this.log(e);this.log("canGoBack threw exception, assuming webview failure with canGoBack as 'false'.");}
    }

    this._bridgeCall('?m=onCanGoBack&status=' + canGoBackStatus);
  },
  _setCanGoBackHandler: function (canGoBackHandler) { //not supported
    this.canGoBackHandler = canGoBackHandler;
  },
  setBackStatus: function (back, home){
    var homeConfirm = (typeof home === 'undefined' ||  home === 0) ? 'false' : 'true';
    this._bridgeCall('?m=setBackStatus&back='+ (back === 1 ? 'true' : 'false') + "&home=" + homeConfirm);
  },
  setGoBackHandler: function (goBackHandler) {
    this.goBackHandler = goBackHandler;
  }, 
  downloadFile: function (url, filename, title, desc){
    this._bridgeCall('?m=downloadFile&url=' + url + "&fileName=" + filename + "&title=" + title + "&desc=" + desc);
  },
  deleteDownloadedFiles: function (){
    this._bridgeCall('?m=deleteDownloadedFiles');
  },
  disableLoadingOtherUrl: function (disable){
    var disableConfirm = (typeof disable === 'undefined' ||  disable === 0) ? 'false' : 'true';
    this._bridgeCall('?m=disableLoadingOtherUrl&disable=' + disableConfirm);
  },
  _goBackHandler: function (isHomeButton) {
    //var goBackStatus=false;
    if(typeof this.goBackHandler === 'function'){
      try{
        //goBackStatus = this.goBackHandler(isHomeButton);        
      }catch(e){this.log(e);this.log("goBack threw exception, assuming webview failure with goBack as 'false'.");}
    }
    //this._bridgeCall('?m=onGoBack&status=' + goBackStatus);
  }, 
  setEventHandler: function (eventHandler) {
    $(this.bridgeFrame).unbind('_applicationdidbecomeactive').bind('_applicationdidbecomeactive', eventHandler);
    $(this.bridgeFrame).unbind('_applicationwillresignactive').bind('_applicationwillresignactive', eventHandler);
    $(this.bridgeFrame).unbind('_urlredirection').bind('_urlredirection', eventHandler);
    $(this.bridgeFrame).unbind('_ssosigninsuccess').bind('_ssosigninsuccess', eventHandler);
    $(this.bridgeFrame).unbind('_ssosignout').bind('_ssosignout', eventHandler);
    $(this.bridgeFrame).unbind('_ssosigninerror').bind('_ssosigninerror', eventHandler);
    $(this.bridgeFrame).unbind('_ssosignincancel').bind('_ssosignincancel', eventHandler);
    $(this.bridgeFrame).unbind('_ssorefreshsuccess').bind('_ssorefreshsuccess', eventHandler);
    $(this.bridgeFrame).unbind('_ssorefresherror').bind('_ssorefresherror', eventHandler);
    $(this.bridgeFrame).unbind('_networkconnection').bind('_networkconnection', eventHandler);
    $(this.bridgeFrame).unbind('_webviewactive').bind('_webviewactive', eventHandler);
    $(this.bridgeFrame).unbind('_webviewinactive').bind('_webviewinactive', eventHandler);
  },
};

var VZWebLib = (navigator.userAgent.toUpperCase().indexOf("VZAPP-ANDROID") > 0) ? androidNativeInterface : VZWebLib_iOS;

VZWebLib.fetchFYHRequestCallback = function(){};
var fyhCookieFetStartTime = null;

VZWebLib.deleteOtherCookies = function () {
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)===' ') c = c.substring(1,c.length);
    var cname = c.substring(0, c.indexOf("="));
    if(KEEP_ONLY_THESE_COOKIES.indexOf(cname) < 0)
      this.eraseCookie (cname);
	}
	return;
};

VZWebLib.eraseCookie = function (name) {
  //this.log("FYH cookie, deleting cookie:" + name);
	this.createCookie(name,"",-1);
}

VZWebLib.createCookie = function (name,value,mins) {
  var expires = ""
	if (mins) {
		var date = new Date();
		date.setTime(date.getTime()+(mins*60*1000));
		expires = "; expires="+date.toGMTString();
	}
	else expires = "";
	var valueType = name+"="+value+expires+"; Domain=.verizon.com;path=/";
  document.cookie = valueType;

  //VZWebLib.log("FYH cookie created:" + value);
};

VZWebLib.readCookie = function (name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)===' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
};

VZWebLib.fetchFYHCookie = function (requestCallBack, timeoutMilliSec, cookieExpiryMins) {

    VZWebLib.fyhCookieExpiryMins = (typeof cookieExpiryMins === 'undefined') ? VZWebLib_FYH_COOKIE_EXPIRY_MINS : cookieExpiryMins;
    fyhCookieFetStartTime = new Date().getTime();
    VZWebLib.log("FYH cookie requested.");
    var libScriptEl = $('script[src*="myvzweb-lib.js"]')[0];
    var libFYHScriptUrl = libScriptEl.src.substring(0, libScriptEl.src.lastIndexOf("/") + 1) + "myvzweb-lib-fyh.js?r="+fyhCookieFetStartTime;
    libFYHScriptUrl = VZWebLib_FYH_URL + encodeURIComponent(libFYHScriptUrl);
    VZWebLib.log("FYH cookie script url: " + libFYHScriptUrl);

    VZWebLib.FYHScriptElement = document.createElement("script");
    VZWebLib.FYHScriptElement.setAttribute("src", libFYHScriptUrl);
    document.head.appendChild(VZWebLib.FYHScriptElement);

    // doesn't work on android 2.3
    //$("body").append($("<script />", {type: "text/javascript",src: libFYHScriptUrl}));
    
    VZWebLib.fetchFYHRequestCallback = requestCallBack;
    VZWebLib.fetchFYHTimeout = setTimeout(function () { VZWebLib.fetchFYHCookieOnLoadCallBack(false) }, timeoutMilliSec);
};

VZWebLib.fetchFYHCookieOnLoadCallBack = function (statusSuccess) {

  if(typeof VZWebLib.FYHScriptElement === 'object') document.head.removeChild(VZWebLib.FYHScriptElement);
  var totalTime = new Date().getTime() - fyhCookieFetStartTime;
  VZWebLib.log("FYH cookie call back received, success?: " + statusSuccess + ", time: " + totalTime);
  window.clearTimeout(VZWebLib.fetchFYHTimeout);

  if(statusSuccess){
    var fyhValue = VZWebLib.readCookie("foryourhome");
    VZWebLib.log("FYH cookie, updating with expiry in mins: " + VZWebLib.fyhCookieExpiryMins);
    VZWebLib.createCookie("foryourhome", fyhValue, VZWebLib.fyhCookieExpiryMins);
    //fyhValue = VZWebLib.readCookie("foryourhome");
    //VZWebLib.log("FYH cookie after expiry change value: " + fyhValue);
  }

  //VZWebLib.deleteOtherCookies();

  if(typeof VZWebLib.fetchFYHRequestCallback === 'function') 
    VZWebLib.fetchFYHRequestCallback(statusSuccess);
};

//VZWebLib.log("document.URL: " + document.URL + ", index: " + document.URL.indexOf('fetchFYH'));

if(document.URL.indexOf('fetchFYH') > 0){
  //VZWebLib.fetchFYHCookie(null, 10000);
}

// end android app native interface
export default VZWebLib