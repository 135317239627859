const strAppInitialState = {
  visitId: '',
  visitorId: '',
  prevPage: '',
  currentPage: '',
  lboMessage: '',
  headerContent: '',
  flowType: 'NC',
  flowSubtype: '',
  clientIp: '',
  acceptHeader: '',
  isMvaFlow: false,
  isMVAFiosEqipFlow: false,
  mvaType: '',
  mvaPageRequestUrl: '',
  isLoader: {
    isLoaderActive: false,
    isLoaderText: "Just a moment,<br/> we're looking it up now.",
    isLoaderImg: 'spinner',
    loaderCount: 0,
  },
  pgLoggerObj: {
    enableUserAction: false,
    enableApiTiming: false,
    enablePageLoad: false,
  },
  oneVzToAccessoriesRoute: false
};

//   const  appInitialState = JSON.parse(strAppInitialState);

function app(state = strAppInitialState, action) {
  switch (action.type) {
    case 'HEADERCONTENTFROMAPI':
      return Object.assign({}, state, {
        headerContent: action.value,
      });

    case 'UPDATEFLOWTYPE': {
      return Object.assign({}, state, {
        flowType: action.value,
      });
    }
    case 'UPDATEFLOWSUBTYPE': {
      return Object.assign({}, state, {
        flowSubtype: action.value,
      });
    }
    case 'UPDATE_VISITID':
      const { visitId, visitorId } = action.value;
      return Object.assign({}, state, {
        visitId,
        visitorId,
      });
    case 'UPDATE_CLIENTIP': {
      return { ...state, clientIp: action.value };
    }
    case 'UPDATE_MVATYPE': {
      return { ...state, mvaType: action.value };
    }
    case 'MVAFLOW': {
      return { ...state, isMvaFlow: action.value };
    }
    case 'MVAFIOSEQP': {
      return { ...state, isMVAFiosEqipFlow: action.value };
    }
    case 'MVAREQUESTURL': {
      return { ...state, mvaPageRequestUrl: action.value };
    }
    case 'UPDATE_ACCEPT_HEADER': {
      return { ...state, acceptHeader: action.value };
    }
    case 'UPDATE_MLPFLOW': {
      return { ...state, mlpFlow: action.value };
    }
    case 'UPDATE_MLPPDP': {
      return { ...state, mlpPDP: action.value };
    }
    case 'ONEVZ_TO_ACCESSORIES_ROUTE': {
      return { ...state, oneVzToAccessoriesRoute: action.value };
    }
    case 'LOADER_ACTIVE':
      let loaderactive = '';
      // if ((action.value.isLoaderText !== undefined || action.value.isLoaderImg !== undefined) &&
      //     action.value.isLoaderActive === true) {
      //     loaderactive = Object.assign({}, state.isLoader, {
      //         isLoaderActive: action.value.isLoaderActive,
      //         isLoaderText: action.value.isLoaderText,
      //         isLoaderImg: action.value.isLoaderImg,
      //         loaderCount: state.isLoader.loaderCount + 1
      //     })
      // }
      if (action.value.isLoaderActive === true) {
        loaderactive = Object.assign({}, state.isLoader, {
          isLoaderActive: action.value.isLoaderActive,
          isLoaderText: action.value.isLoaderText ? action.value.isLoaderText : '',
          isLoaderImg: action.value.isLoaderImg ? action.value.isLoaderImg : '',
          loaderCount: state.isLoader.loaderCount + 1,
        });
      } else if (state.isLoader.loaderCount <= 1 || (action.value.isLoaderActive === false && action.value.isLoaderText === 'RESET_LOADER')) {
        // Added isLoaderText === "RESET_LOADER" condition to make the loader reset immediately, To avoid clocking issues.
        //RESET_LOADER values will get dispatched only particular methods like homepage banner call, category page pdp call, product page pdp details call because those are last api calls of respective pages.
        loaderactive = Object.assign({}, state.isLoader, {
          isLoaderActive: action.value.isLoaderActive,
          isLoaderText: '',
          loaderCount: 0,
        });
      } else {
        loaderactive = Object.assign({}, state.isLoader, {
          loaderCount: state.isLoader.loaderCount - 1,
        });
      }
      
      return Object.assign({}, state, {
        isLoader: loaderactive,
      });
    default:
      return state;
  }
}

export default app;
