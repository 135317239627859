const errorInitialState = {
  errorCode: '',
  errorPage: '',
  errorTitle: '',
  clientExceptions: {
    hasError: false,
    errorMessage: '',
    errorStock: '',
  },
};

//define a reducer with an initialized state action
function error(state = errorInitialState, action) {
  //eventually adding logic to handle create, update, and delete
  switch (action.type) {
    case 'LBO':
      return Object.assign({}, state, {
        errorCode: action.value,
        errorPage: action.frompage,
        errorTitle: action.error,
      });
    case 'CLIENT_EXCEPTION':
      let clienterrors = Object.assign({}, state.clientExceptions, {
        hasError: action.value.hasError,
        errorMessage: action.value.errorMessage,
      });
      return Object.assign({}, state, {
        clientExceptions: clienterrors,
      });
    default:
      return state;
  }
}

export default error;
