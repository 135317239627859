import { push, goBack as goBackRouter } from 'connected-react-router';

export function redirectTo(page, dispatch) {
  return dispatch(push(page));
  // return props.history.push(page);
}

export function goBack(dispatch) {
  return dispatch(goBackRouter());
}
