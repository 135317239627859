// import {productsAction}  from '../action/productsAction'
const initialState = {
  showCart: false,
  cartProduct: [],
  subTotal: '',
  total: '',
  shippingcrg: '',
  shippingDiscount: '',
  tax: '',
  shoppingCartContent: '',
};

const cartProductDetails = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOPPINGCARTCONTENTFROMAPI':
      return Object.assign({}, state, {
        shoppingCartContent: action.value,
      });
    case 'CARTPRODUCTSDETAILSFROMJSON': {
      return {
        ...state,
        cartProduct: action.value,
        subTotal: action.subTotal.toFixed(2) /**Price formatting if 10 means 10.00 */,
        total: action.total.toFixed(2),
        shippingcrg: action.shippingcrg,
        shippingDiscount: action.shippingDiscount,
        tax: action.tax,
      };
    }
    case 'SHOWCART': {
      return { ...state, showCart: action.value };
    }

    default:
      return state;
  }
};
export default cartProductDetails;
