import React, { Suspense, lazy } from 'react';
// import { BrowserRouter, Switch, Route } from 'react-router-dom';
//import asyncComponent from './../componentsSMB/asyncComponent';

import { Route, Switch } from 'react-router';
import ErrorBoundary from '../service/errorBoundary';
// import Accessories from './../containersSMB/accessories/index';
// import WrapperProduct from '../containersSMB/wrapper/wrapperProduct';
// import WrapperProductDetail from './../containersSMB/wrapper/wrapperProductDetails';
// import beginCheckout from './../containersSMB/beginCheckout/index';
// import Checkout from './../containersSMB/checkout/index';
// import ShopCart from '../containersSMB/Cart/shoppingCart/index';
// import orderSubmit from '../containersSMB/orderSubmit/index';

const Accessories = lazy(() => import('./../containersSMB/accessories/index'));
const WrapperProduct = lazy(() => import('../containersSMB/wrapper/wrapperProduct'));
//const WrapperProductDetail = lazy(() => import('./../containersSMB/wrapper/wrapperProductDetails'));
const beginCheckout = lazy(() => import('./../containersSMB/beginCheckout/index'));
const Checkout = lazy(() => import('./../containersSMB/checkout/index'));
const ShopCart = lazy(() => import('../containersSMB/Cart/shoppingCart/index'));
const orderSubmit = lazy(() => import('../containersSMB/orderSubmit/index'));
const lbo = lazy(() => import('./../containersSMB/lbo/index'));
const custError = lazy(() => import('./../componentsSMB/custError'));
const cacheRefresh = lazy(() => import('./../componentsSMB/cacheRefresh'));
// import Accessories from './../containersSMB/accessories/index';
// import WrapperProduct from './../containersSMB/wrapper/wrapperProduct';
// import WrapperProductDetail from './../containersSMB/wrapper/wrapperProductDetails';
// import Test from './../containersSMB/test/index.js';

// const Accessories = asyncComponent(() =>
//     import('./../containersSMB/accessories/index').then(module => module.default)
// )
// const WrapperProduct = asyncComponent(() =>
//     import('../containersSMB/wrapper/wrapperProduct').then(module => module.default)
// )
// const WrapperProductDetail = asyncComponent(() =>
//     import('./../containersSMB/wrapper/wrapperProductDetails').then(module => module.default)
// )
// const beginCheckout = asyncComponent(() =>
//     import('./../containersSMB/beginCheckout/index').then(module => module.default)
// )
// const Checkout = asyncComponent(() =>
//     import('./../containersSMB/checkout/index').then(module => module.default)
// )
// const ShopCart = asyncComponent(() =>
//     import('../containersSMB/Cart/shoppingCart/index').then(module => module.default)
// )
// const orderSubmit = asyncComponent(() =>
//     import('../containersSMB/orderSubmit/index').then(module => module.default)
// )

const routes = (
  <React.Fragment>
    {/* <BrowserRouter> */}
    <ErrorBoundary>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/smallbusiness/accessories" component={Accessories} />
          <Route exact path="/smallbusiness/accessories/begincheckout" component={beginCheckout} />
          <Route exact path="/smallbusiness/accessories/g/ordersubmit" component={orderSubmit} />
          <Route exact path="/smallbusiness/accessories/s/ordersubmit" component={orderSubmit} />
          <Route exact path="/smallbusiness/accessories/g/checkout" component={Checkout} />
          <Route exact path="/smallbusiness/accessories/s/checkout" component={Checkout} />
          <Route exact path="/smallbusiness/accessories/lbo" component={lbo} />
          <Route exact path="/smallbusiness/accessories/shoppingcart" component={ShopCart} />
          <Route exact path="/smallbusiness/accessories/cacherefresh" component={cacheRefresh} />
          <Route exact path="/smallbusiness/accessories/:pageName" component={WrapperProduct} />
          <Route exact path="/smallbusiness/accessories/:pageName/:compatibility" component={WrapperProduct} />
          <Route exact path="/smallbusiness/accessories/:pageName/:filter/:filterName" component={WrapperProduct} />
          {/* <Route exact path="/smallbusiness/accessories/:pageName/:subPageName" component={WrapperProductDetail} /> */}

          <Route path="*" component={custError} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
    {/* </BrowserRouter>  */}
  </React.Fragment>
);

export default routes;
