import React from 'react';
import { Utils } from '../util/utils';
import axios from 'axios';
class GlobalHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  loadScript = () => {
    try {
      let url = Utils.getDomainUrl('nte1').concat('/etc/clientlibs/verizon-atom/ak-cached/2h/gheader.min.js');
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      document.body.appendChild(script);
    } catch (ex) {
      // catchJSException('Checkout', "", 'loadScript', ex);
    }
  };
  componentDidMount() {
    this.loadScript();
  }
  componentWillMount() {
    let headerHtml;
    let url = Utils.getDomainUrl('nte1').concat('/includes/commongh/?type=modularghf&clientid=resaccessories');
    axios
      .get(url)
      .then(
        function (res) {
          headerHtml = res.data;
          this.setState({ headerHtml });
        }.bind(this),
      )
      .catch(function (error) {
        //alert(error);
      });
  }
  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.headerHtml }} />;
  }
}
export default GlobalHeader;
