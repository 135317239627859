import { store } from './../index';
import { logOtherInfo, detailsInfo } from './../service/logService';
import { redirectTo } from './../action/redirection';
import {
  setNativeTitleWithNavigation,
  showHideBtmTabBar,
  showHideWishListCart,
  hideWishListCart,
  setTitleWithNavigationPattern,
  hideBtmTabBar,
} from './native';
import queryString from 'querystring';
export class Utils {
  static isNull(value) {
    if (value === undefined || value === null) {
      return true;
    }
    return false;
  }

  // static getAccountId(){
  //     let value = ""
  //     let profileInfo =  ((store.getState().checkout.profileInfo !== undefined && store.getState().checkout.profileInfo !== null && store.getState().checkout.profileInfo !== "") ? store.getState().checkout.profileInfo : "")
  //     if(profileInfo !== ""){
  //         let profileResponse = (( profileInfo.profileResponse !== undefined && profileInfo.profileResponse !== null && profileInfo.profileResponse !== "") ? profileInfo.profileResponse : "")
  //         if(profileResponse && profileResponse !== ""){
  //             value = ((profileResponse.accountId !== undefined && profileResponse.accountId !== null && profileResponse.accountId !== "") ? profileResponse.accountId : "")
  //             return value
  //         } else{
  //             value = ""
  //             return value
  //         }
  //     }
  // }

  // static getAccCreationDate(){
  //     let regDate = ""
  //     let profileInfo =  ((store.getState().checkout.profileInfo !== undefined && store.getState().checkout.profileInfo !== null && store.getState().checkout.profileInfo !== "") ? store.getState().checkout.profileInfo : "")
  //     if(profileInfo !== ""){
  //         let profileResponse = ((profileInfo.profileResponse !== undefined && profileInfo.profileResponse !== null && profileInfo.profileResponse !== "") ? profileInfo.profileResponse : "")
  //         if(profileResponse && profileResponse !== ""){
  //             regDate = ((profileResponse.accountCreationDate !== undefined && profileResponse.accountCreationDate !== null && profileResponse.accountCreationDate !== "") ? profileResponse.accountCreationDate : "")
  //             return regDate
  //         } else{
  //             regDate = ""
  //             return regDate
  //         }
  //     }
  // }

  static isNotNull(value) {
    if (typeof value !== 'string' && value !== undefined && value !== null) {
      return true;
    } else if (typeof value === 'string' && value !== undefined) {
      return true;
    }
    return false;
  }

  static isNotEmpty(value) {
    if (value !== undefined && value !== null && value !== '') {
      return true;
    }
    return false;
  }

  // static isNotNullObjects(obj /*, level1, level2, ... levelN*/) {
  //     try {
  //         var args = Array.prototype.slice.call(arguments, 1);

  //         for (var i = 0; i < args.length; i++) {
  //             if (!obj || !obj.hasOwnProperty(args[i])) {
  //                 return false;
  //             }
  //             obj = obj[args[i]];
  //         }
  //         return true;
  //     }
  //     catch (e) { return false; }
  // }

  // static arrayIncludes(array, value) {
  //     if (array.includes) {
  //         return array.includes(value);
  //     }
  //     else {
  //         return array.indexOf(value) > -1 ? true : false;
  //     }
  // }

  static removeSpecialChars(str) {
    let newstr = str.toLowerCase();
    return newstr
      .replace(/(?!\w|\s)./g, '')
      .replace(/\s+/g, '-')
      .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
  }

  // static modifyUrl(str){
  //     let m,n,
  //         urls = [],url=[],
  //         rex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g,
  //         reg=/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/g;

  //     while ( m === rex.exec( str ) ) {
  //         urls.push( m[1] );
  //     }
  //     while ( n === reg.exec( str ) ) {
  //         url.push( n[2] );
  //     }
  //     for(let i=0;i<urls.length;i++){
  //      str=str.replace(urls[i],Utils.getDomainUrl()+urls[i])
  //     }
  //    if(url.length>0){
  //      str=str.replace(url[0],Utils.getDomainUrl()+url[0])
  //     }
  //     return str

  // }

  static clearMemory() {
    let visitId = store.getState().app.visitId;
    let pageName = store.getState().app.currentPage.pathname;
    logOtherInfo(detailsInfo(pageName, visitId, 'clearMemory', 'Clearing both cookie and redux', 'USERACTION'));
    window.sessionStorage.clear();
    window.localStorage.clear();
    clearCookies();
    store.dispatch({ type: 'CLEAR_STORE', value: null });
  }

  static clearRedux(...storesToClear) {
    for (let i = 0; i < storesToClear.length; i++) {
      let state = storesToClear[i];
      if ('null' !== state) {
        let stateUpperCase = state.toUpperCase();
        let typeData = 'CLEAR_'.concat(stateUpperCase);
        store.dispatch({ type: typeData, value: null });
      }
    }
  }
  static mvaAppVersionCheck() {
    let versionKey = 'MF_APP_VERSION';
    let mvaAppVersion = '';
    if (window.navigator.userAgent) {
      //let userAgent = "Mozilla/5.0 (Linux; Android 12; SM-S908U Build/SP1A.210812.016; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/99.0.4844.88 Mobile Safari/537.36;MY_VZW_APP/9.8.0 (osName:Android; osVersion:11; deviceName:generic_x86; model:Android SDK built for x86; MF_APP_VERSION:7.7; accept-language:en";
      let userAgent = window.navigator.userAgent.toUpperCase();
      if (userAgent && userAgent.includes(versionKey)) {
        let userAgentItems = userAgent.split(';');
        if (userAgentItems && userAgentItems.length) {
          mvaAppVersion = userAgentItems
            .filter((element) => element.includes(versionKey))
            .toString()
            .split(':')[1];
          if (mvaAppVersion) return parseFloat(mvaAppVersion);
        }
      }
    }
  }
  /**get querystring val */
  static getreqPageFrmUrl(searchLocation) {
    let qsvalues;
    if (searchLocation) {
      qsvalues = queryString.parse(searchLocation);
      if (qsvalues && Utils.isNotNull(qsvalues)) {
        qsvalues = Object.assign(...Object.keys(qsvalues).map((key) => ({ [key.toLowerCase()]: qsvalues[key] })));
        if (qsvalues.requestUrl) {
          qsvalues.requestUrl = qsvalues.requestUrl.replace(/ /g, '+');
        }
      }
    }
    return qsvalues;
  }
  /**native title and back buton */
  static showHideNativeTitleBackBtn(mvaType, isFiosEqpFlow, pattern, hideBckBtn, isProductInCart, isLanding, mvaPageRequestUrl) {
    let mvaAppVersion = this.mvaAppVersionCheck();
    const ua = navigator.userAgent;
    let headerTitle = '';
    let showBackBtn = true;
    let btnImage = '';
    hideWishListCart();
    if (hideBckBtn) {
      showBackBtn = !hideBckBtn;
    }
    //if (/iPad|iPhone|iPod/.test(ua)  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
    if (/android/i.test(ua)) {
      if (isProductInCart && isLanding) {
        showBackBtn = true;
      } else {
        showBackBtn = false;
      }
    }
    if (mvaType && mvaType == 'vzw') {
      headerTitle = isFiosEqpFlow ? 'Fios equipment' : 'Home Accessories';
      // hideWishListCart();
      setNativeTitleWithNavigation(headerTitle, showBackBtn, isProductInCart, isLanding, mvaPageRequestUrl);
    } else if (mvaType && mvaType == 'vzt') {
      headerTitle = isFiosEqpFlow ? 'Fios equipment' : 'Shop';
      let versionToCheck = 8;
      if (mvaAppVersion && mvaAppVersion >= versionToCheck) {
        if (pattern === 'B') {
          btnImage = 'nav_back';
          showHideBtmTabBar(false);
          // showHideWishListCart(false,true);
        } else if (pattern === 'D') {
          btnImage = 'nav_close';
          hideBtmTabBar();
          // hideWishListCart();
        }
        setTitleWithNavigationPattern(headerTitle, showBackBtn, btnImage, isProductInCart, isLanding, mvaPageRequestUrl);
      } else {
        setNativeTitleWithNavigation(headerTitle, showBackBtn, isProductInCart, isLanding, mvaPageRequestUrl);
      }
    }
  }

  static redirectToHomePage() {
    redirectTo('/home/accessories', store.dispatch);
  }
  static isMobileView() {
    let userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent && (userAgent.indexOf('android') !== -1 || userAgent.indexOf('iphone') !== -1)) {
      return true;
    }
  }
  static getBrowserType() {
    let userAgent = window.navigator.userAgent.toLowerCase();
    let browserType;
    if (userAgent.indexOf('trident') !== -1) {
      browserType = 'internet explorer';
    } else if (userAgent.indexOf('firefox') !== -1) {
      browserType = 'firefox';
    } else if (userAgent.indexOf('edge') !== -1 || userAgent.indexOf('edg') !== -1) {
      browserType = 'edge';
    } else if (userAgent.indexOf('chrome') !== -1) {
      browserType = 'chrome';
    } else if (userAgent.indexOf('safari') !== -1) {
      browserType = 'safari';
    } else {
      browserType = 'other';
    }
    return browserType;
  }
  static getDomainUrl(env = '') {
    let domainURL = window.location.origin;
    // let CatalogOneEnabled = true;
    if (
      domainURL === 'https://www.verizon.com' ||
      domainURL === 'https://wwwv05g-vtp-east-yz.vpc.verizon.com' ||
      domainURL === 'https://wwwv05g-vtp-west-yz.vpc.verizon.com'
    ) {
      domainURL = 'https://www.verizon.com';
    } else if (domainURL === 'https://espanol.verizon.com') {
      domainURL = 'https://www.verizon.com';
    } else if (domainURL === 'https://orderinglite-gz-east.vpc.verizon.com') {
      domainURL = 'https://www98.verizon.com';
    } else if (domainURL === 'https://preprod-east-gz.vpc.verizon.com') {
      domainURL = 'https://www.verizon.com';
    } else if (domainURL === 'https://www98.verizon.com') {
      //domainURL = "https://www98.verizon.com"
      domainURL = 'https://www.verizon.com';
    }
    //else if(domainURL === "https://wwwv05g-vtp-east-yz.vpc.verizon.com"){
    //domainURL = "https://wwwv05g-vtp-east-yz.vpc.verizon.com"
    //}
    else if (domainURL === 'https://sso-pr-east-yz.vpc.verizon.com') {
      domainURL = 'https://www.verizon.com';
    } else if (domainURL === 'https://wwwawssit.ebiz.verizon.com') {
      //domainURL = "https://wwwawssit.ebiz.verizon.com";
      domainURL = 'https://www.verizon.com';
    } else {
      if (env === 'nte1') {
        domainURL = 'https://wwwnte1aws.ebiz.verizon.com';
      } else {
        domainURL = 'https://wwwawssit.ebiz.verizon.com';
      }
    }
    return domainURL;
  }
  static getImagePath(imagePath) {
    let actualImagePathInfo = '';
    let domainURL = window.location.origin;
    if (imagePath.indexOf('/home/accessories/media/') > -1) {
      if (domainURL != 'https://wwwawssit.ebiz.verizon.com') {
        actualImagePathInfo = 'https://ss7.vzw.com/is/image/VerizonWireless/' + imagePath;
      } else {
        actualImagePathInfo = Utils.getDomainUrl().concat(imagePath);
      }
    } else {
      actualImagePathInfo = 'https://ss7.vzw.com/is/image/VerizonWireless/' + imagePath;
    }
    return actualImagePathInfo;
  }
  static getContentPath(contentPath) {
    let actualContentPath = '';
    let domainURL = window.location.origin;
    if (contentPath.indexOf('/home/accessories/media/') > -1) {
      if (domainURL == 'https://wwwnte1aws.ebiz.verizon.com') {
        actualContentPath = 'https://ss7.vzw.com/is/content/VerizonWireless/' + contentPath;
      } else {
        actualContentPath = Utils.getDomainUrl().concat(contentPath);
      }
    }
     else {
      if (contentPath.indexOf('/www.adobe.com/') > -1 || contentPath.indexOf('/www.verizon.com/') > -1) {
        actualContentPath = contentPath;
      }
      else if(contentPath.indexOf('/home/accessories/') > -1){
        actualContentPath = Utils.getDomainUrl().concat(contentPath);
      } 
      else {
        actualContentPath = 'https://ss7.vzw.com/is/content/VerizonWireless/' + contentPath;
      }
    }
    return actualContentPath;
  }
  static getStyleObjectFromString(str) {
    console.log(str);
    const style = {};
    str.split(';').forEach((el) => {
      const [property, value] = el.split(':');
      if (!property) return;

      const formattedProperty = formatStringToCamelCase(property.trim());
      style[formattedProperty] = value ? value.trim() : '';
    });

    console.log(style);
    return style;
  }
  static getCookieByPartialKey = (partialKey)=>{

    const cookies = document.cookie.split('; ');
    const requiredCookie  = cookies.find(cookie => cookie.startsWith(partialKey));
    if(requiredCookie){
      return requiredCookie.split('=')[1];
    }
    return null ;
  }
}

function clearCookies() {
  var cookies = document.cookie.split('; ');
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split('.');
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
      var p = window.location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
}

const formatStringToCamelCase = (str) => {
  const splitted = str.split('-');
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join('')
  );
};


