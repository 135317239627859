const initialState = {};
function orderSubmit(state = initialState, action) {
  switch (action.type) {
    case 'UPDATEORDERSUBMITSTATUS': {
      return { ...state, orderPlaced: action.value };
    }
    case 'CONFIRMBOXCONTENTFROMAPI': {
      return { ...state, orderSubmissionPageContent: action.value };
    }
    default:
      return state;
  }
}
export default orderSubmit;
