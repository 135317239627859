import { combineReducers } from 'redux';
import categories from './categories';
import products from './products';
import prodDetails from './prodDetails';
import checkout from './checkout';
import cartProductDetails from './cartProducts';
import faqDetails from './faq';
import error from './error';
import storageSession from 'redux-persist/lib/storage/session';
import localSession from 'redux-persist/lib/storage';
import app from './app';
import orderSubmit from './orderSubmit';

// const initialState = {
//   "articles": []
// };
const appReducer = combineReducers({
  appVTP: app,
  categoriesVTP: categories,
  productsVTP: products,
  prodDetailsVTP: prodDetails,
  checkoutVTP: checkout,
  //  personalInfo: personalInfo,
  cartProductDataVTP: cartProductDetails,
  orderSubmitVTP: orderSubmit,
  faqDetailsVTP: faqDetails,
  errorVTP: error,
});

/*const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  app: initialState
})*/

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    Object.keys(state).forEach((key) => {
      storageSession.removeItem(`persist:${key}`);
      localSession.removeItem(`persist:${key}`);
    });
    state = undefined;
  }
  if (action.type === 'CLEAR_CATEGORIES') {
    state = { ...state, categoriesVTP: undefined };
  }
  if (action.type === 'CLEAR_PRODUCTS') {
    state = { ...state, productsVTP: undefined };
  }
  if (action.type === 'CLEAR_PRODDETAILS') {
    state = { ...state, prodDetailsVTP: undefined };
  }
  if (action.type === 'CLEAR_CHECKOUT') {
    state = { ...state, checkoutVTP: undefined };
  }
  if (action.type === 'CLEAR_CARTPRODUCTDATA') {
    state = { ...state, cartProductDataVTP: undefined };
  }
  if (action.type === 'CLEAR_FAQDETAILS') {
    state = { ...state, faqDetailsVTP: undefined };
  }
  if (action.type === 'CLEAR_ERROR') {
    state = { ...state, errorVTP: undefined };
  }
  if (action.type === 'CLEAR_ALL') {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
