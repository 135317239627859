import { combineReducers } from 'redux';
import categories from './categories';
import products from './products';
import prodDetails from './prodDetails';
import checkout from './checkout';
import cartProductDetails from './cartProducts';
import faqDetails from './faq';
import error from './error';
import storageSession from 'redux-persist/lib/storage/session';
import localSession from 'redux-persist/lib/storage';
import app from './app';
import orderSubmit from './orderSubmit';

// const initialState = {
//   "articles": []
// };
const appReducerSMB = combineReducers({
  appSMB: app,
  categoriesSMB: categories,
  productsSMB: products,
  prodDetailsSMB: prodDetails,
  checkoutSMB: checkout,
  //  personalInfo: personalInfo,
  cartProductDataSMB: cartProductDetails,
  orderSubmitSMB: orderSubmit,
  faqDetailsSMB: faqDetails,
  errorSMB: error,
});

/*const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  app: initialState
})*/

const rootReducerSMB = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    Object.keys(state).forEach((key) => {
      storageSession.removeItem(`persist:${key}`);
      localSession.removeItem(`persist:${key}`);
    });
    state = undefined;
  }
  if (action.type === 'CLEAR_CATEGORIES') {
    state = { ...state, categoriesSMB: undefined };
  }
  if (action.type === 'CLEAR_PRODUCTS') {
    state = { ...state, productsSMB: undefined };
  }
  if (action.type === 'CLEAR_PRODDETAILS') {
    state = { ...state, prodDetailsSMB: undefined };
  }
  if (action.type === 'CLEAR_CHECKOUT') {
    state = { ...state, checkoutSMB: undefined };
  }
  if (action.type === 'CLEAR_CARTPRODUCTDATA') {
    state = { ...state, cartProductDataSMB: undefined };
  }
  if (action.type === 'CLEAR_FAQDETAILS') {
    state = { ...state, faqDetailsSMB: undefined };
  }
  if (action.type === 'CLEAR_ERROR') {
    state = { ...state, errorSMB: undefined };
  }
  if (action.type === 'CLEAR_ALL') {
    state = undefined;
  }
  return appReducerSMB(state, action);
};
export default rootReducerSMB;
