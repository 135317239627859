import axios from 'axios';
import { apiUrl } from './../constants/apiUrl';
import { logApiTime } from '../service/logService';
import { Utils } from './../util/utils';
//import { store } from './../index';

let CancelToken = axios.CancelToken,
  currentRequest = { cancel: null, url: null },
  tokenGenFailureCount = 0,
  sameTokenIssued = false,
  tokenDuration = 0,
  tokenUpdatedTime = 0,
  tokenValidUpto = 0,
  proxyUrl = '',
  access_token = '',
  lQVisitIdCount = 0;
const oldRequestCancelled = 'old Request Cancelled',
  proxyEnabled =
    document.getElementById('hdnProxyEnabled') && document.getElementById('hdnProxyEnabled').value
      ? document.getElementById('hdnProxyEnabled').value
      : true,
  hostName = window.location.host,
  HTML_CONTENT = 'html',
  TOKEN_BEARER = 'Bearer ',
  IH_MAX_RETRY_LIMIT = 10,
  IH_KEY_VST_EXPIRY_PATH = 'VstExpiryPath',
  API_URL_KAFKA = '/kafka/',
  TOKEN_URL = '/generatetoken',
  TIMESTAMP_QPARM = '?timestamp=',
  APPLICATION_JSON = 'application/json',
  NO_CACHE = 'no-cache',
  NO_STORE = 'no-store',
  Q_PARM = '?',
  TOKEN_HEADER = {
    'Content-Type': APPLICATION_JSON,
    Pragma: NO_CACHE,
    'Cache-Control': NO_STORE,
  },
  API_URL_GENERATE_VISIT_ID = 'generatevisitid',
  API_URL_GET_SERVER_VISIT_ID_AND_VISITOR_ID = '/home/accessories/getservervisitidandvisitorid',
  API_URL_CLEAR_COOKIE_VISIT_ID = '/home/accessories/clearvisitid';

if (hostName.indexOf('localhost') > -1) proxyUrl = 'http://localhost:8082/proxy';
else proxyUrl = hostName + '/myvzservices/Services/atom/proxy';

const api = (url, methodType, data, contentType, cancelMultipleRequest, browserInfo) => {
  return new Promise((resolve, reject) => {
    if (JSON.parse(proxyEnabled)) {
      if (contentType === HTML_CONTENT) callingProxyhtml(resolve, url, methodType, data, contentType);
      else callingProxy(resolve, url, methodType, data, contentType, browserInfo);
    } else {
      if (Date.now() >= tokenValidUpto) {
        tokenGeneration().then((response) => {
          if (response.status === 200) {
            if (contentType === HTML_CONTENT) callingGatewayhtml(resolve, url, methodType, data, contentType);
            else callingGateway(resolve, url, methodType, data, contentType, cancelMultipleRequest);
          }
        });
      } else {
        if (contentType === HTML_CONTENT) callingGatewayhtml(resolve, url, methodType, data, contentType);
        else callingGateway(resolve, url, methodType, data, contentType, cancelMultipleRequest);
      }
    }
  });
};
const tokenGeneration = (url) => {
  return new Promise((resolve, reject) => {
    newTokenGeneration(resolve);
  });
};

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.timing = { startTime: Date.now() };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (res) {
    // Do something with response data
    const endTime = Date.now();
    if (res.config.timing) res.config.timing.duration = endTime - res.config.timing.startTime;
    //console.log('interceptor response', response.config.timing.endTime - response.config.timing.startTime);
    return res;
  },
  function (error) {
    // Do something with response error
    const endTime = Date.now();
    if (error.config) error.message !== oldRequestCancelled && (error.config.timing.duration = endTime - error.config.timing.startTime);
    return Promise.reject(error);
  },
);
const DOMAINS = {
  PROD: 'www.verizon.com',
  PILOT: 'orderinglite-gz-east.vpc.verizon.com',
  PREPROD: 'www.preprod.verizon.com',
  PREPRODEAST: 'preprod-east-gz.vpc.verizon.com',
  STAGE: 'www98.verizon.com',
  SIT: 'wwwawssit.ebiz.verizon.com',
  UAT: 'wwwawsuat.ebiz.verizon.com',
  NTE1: 'wwwnte1aws.ebiz.verizon.com',
  NTE3: 'wwwnte3aws.ebiz.verizon.com',
  NTE4: 'wwwnte4aws.ebiz.verizon.com',
  NTE5: 'wwwnte5aws.ebiz.verizon.com',
  ESPANOL: 'espanol.verizon.com',
};
const INHOME_DOMAINS = {
  // missing mapping in original implemenation for  nte5
  'www.verizon.com': 'GlobalSessionID',
  'orderinglite-gz-east.vpc.verizon.com': 'GlobalSessionID',
  'www.preprod.verizon.com': 'GlobalSessionID',
  'preprod-east-gz.vpc.verizon.com': 'GlobalSessionID',
  'www98.verizon.com': 'GlobalSessionID_98',
  'wwwawssit.ebiz.verizon.com': 'GlobalSessionID_awssit',
  'wwwawsuat.ebiz.verizon.com': 'GlobalSessionID_awsuat',
  'wwwnte1aws.ebiz.verizon.com': 'GlobalSessionID_awsnte1',
  'wwwnte3aws.ebiz.verizon.com': 'GlobalSessionID_awsnte3',
  'wwwnte4aws.ebiz.verizon.com': 'GlobalSessionID_awsnte4',
  'wwwnte5aws.ebiz.verizon.com': '',
  'espanol.verizon.com': 'GlobalSessionID',
};

function callingProxy(resolve, url, methodType, data, contentType, browserInfo) {
  if (contentType === undefined) {
    contentType = APPLICATION_JSON;
  }
  if (browserInfo === undefined) {
    browserInfo = '';
  }
  const Requestobject = {
    RequestUrl: url,
    MethodType: methodType,
    ContentType: contentType,
    Request: data,
    BrowserInfo: browserInfo,
  };

  axios({
    url: proxyUrl,
    method: 'POST',
    responseType: 'json',
    withCredentials: true,
    cache: false,
    data: Requestobject,
    headers: {
      'Content-Type': APPLICATION_JSON,
      'Gsm-Id': getGlobalSessionID(document.cookie)
    },
    timeout: 90000,
  })
    .then((response) => {
      resolve(response.data);
      if (response.config.url.indexOf(API_URL_KAFKA) === -1) {
        logTiming(response.config);
      }
    })
    .catch((error) => {
      resolve(error);
    });
}

function callingProxyhtml(resolve, url, methodType, data, contentType, browserInfo) {
  if (contentType === undefined) {
    contentType = APPLICATION_JSON;
  }
  if (browserInfo === undefined) {
    browserInfo = '';
  }
  const Requestobject = {
    RequestUrl: url,
    MethodType: methodType,
    ContentType: contentType,
    Request: data,
    BrowserInfo: browserInfo,
  };

  axios({
    url: proxyUrl,
    method: 'POST',
    withCredentials: true,
    data: Requestobject,
    cache: false,
    timeout: 90000,
  })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      resolve(error);
    });
}

function resetCurrentRequest() {
  currentRequest = { url: null, cancel: null };
}

function logTiming(config) {
  logApiTime({ url: config.url, time: config.timing.duration, pathName: getPathName(config.url), requestData: config.data });
}

function getPathName(url) {
  const lastPath = url.lastIndexOf('/');
  return (lastPath > -1 && url.slice(lastPath)) || '';
}

async function getAuthHeader() {
  if (!access_token || Date.now() > tokenValidUpto) {
    await fetchFreshToken();
  }
  return TOKEN_BEARER.concat(access_token);
}

function callingGateway(resolve, url, methodType, data, contentType, cancelMultipleRequest) {
  contentType = contentType || APPLICATION_JSON;
  // let cookieToken = getToken(document.cookie);
  const authorization = TOKEN_BEARER + access_token;
  if (cancelMultipleRequest && currentRequest.url && currentRequest.url === url.slice(0, url.indexOf(Q_PARM)) && currentRequest.cancel) {
    currentRequest.cancel(oldRequestCancelled);
  }
  currentRequest = { url: url.slice(0, url.indexOf(Q_PARM)), cancel: null };
  // let storeVisitId = window.localStorage.getItem("visit_id");
  // let cookieVisitID = window.sessionStorage.getItem("visit_id");//store.getState().app.visitId;
  let storeVisitId = window.localStorage.getItem('visit_id_vtp');
  let cookieVisitID = window.sessionStorage.getItem('visit_id_vtp'); //store.getState().app.visitId;
  // let cookie_expirationTime = parseInt(window.localStorage.getItem("expirationTime"));
  let cookie_expirationTime = parseInt(window.localStorage.getItem('expirationTime_vtp'));
  const winPath = window.location.pathname;

  if (cookieVisitID === null && cookie_expirationTime > Date.now()) {
    // window.sessionStorage.setItem('visit_id', storeVisitId);
    window.sessionStorage.setItem('visit_id_vtp', storeVisitId);
    cookieVisitID = storeVisitId;
  }

  let reqConfig = null;
  {
    reqConfig = {
      url: url,
      headers: {
        'Content-Type': contentType,
        Pragma: NO_CACHE,
        'Cache-Control': NO_STORE,
        Authorization: authorization,
      },
      method: methodType,
      responseType: 'json',
      cache: false,
      data: data,
      withCredentials: true,
      crossDomain: true,
      cancelToken: new CancelToken(function executor(cancelFunction) {
        currentRequest.cancel = cancelFunction;
      }),
      timeout: 90000,
    };
  }
  const customGetHeader = methodType === 'GET' && data && typeof data === 'object' ? { ...data } : {};

  reqConfig.headers = Object.assign({}, reqConfig.headers, customGetHeader);

  if (
    (cookieVisitID && cookieVisitID === storeVisitId && cookie_expirationTime && cookie_expirationTime > Date.now()) ||
    url.indexOf(API_URL_KAFKA) > -1 ||
    url.indexOf(API_URL_GENERATE_VISIT_ID) > -1
  ) {
    axios({ ...reqConfig })
      .then((response) => {
        if (response.status === 401 && response.data.RequestError && tokenGenFailureCount < IH_MAX_RETRY_LIMIT) {
          tokenGeneration().then(function (response) {
            callingGateway(resolve, url, methodType, data, contentType, cancelMultipleRequest);
          });
        } else if (
          response.status === 200 &&
          response.data !== null &&
          (!response.data.APIExceptionResponse || response.data.APIExceptionResponse === '')
        ) {
          response.config.url.indexOf(TOKEN_URL) === -1 && tokenGenFailureCount && (tokenGenFailureCount = 0);
          resolve(response.data);
          if (response.config.url.indexOf(API_URL_KAFKA) === -1) {
            logTiming(response.config);
          }
        } else tokenGenFailureCount && (tokenGenFailureCount = 0);

        resetCurrentRequest();
        resolve(response.data);
      })
      .catch((error) => {
        if (
          error.config &&
          error.config.url &&
          error.config.url.indexOf(API_URL_KAFKA) === -1 &&
          error.response &&
          error.response.status === 401 &&
          tokenGenFailureCount < IH_MAX_RETRY_LIMIT
        ) {
          !tokenDuration || Date.now() - tokenUpdatedTime < tokenDuration;
          tokenGeneration().then(function (response) {
            response && callingGateway(resolve, url, methodType, data, contentType, cancelMultipleRequest);
          });
        } else if (error.response && error.response.status && parseInt(error.response.status) >= 500) {
          resolve({ status: error.response.status, data: null }); //added this to avoid redux persist error on HTTP 5xx
        } else
          error.message && error.message === oldRequestCancelled
            ? ''
            : resolve({ status: (error.response && error.response.status) || 401, data: null }); //added this to avoid redux persist error on HTTP 5xx
      });
  } else {
    //if visit_id got expired
    console.error('api url', url);
    console.error('storeVisitId', storeVisitId);
    console.error('cookie_expirationTime', cookie_expirationTime);
    console.error('cookieVisitID', cookieVisitID);
    console.error('window.location.href ', window.location.href);
    {
      Utils.clearMemory();
      resetCurrentRequest();
      Utils.redirectToHomePage();
    }
  }
}

function callingGatewayhtml(resolve, url, methodType, data, contentType) {
  axios({
    url: url,
    method: methodType,
    cache: false,
    data: data,
    withCredentials: true,
    crossDomain: true,
    headers: {
      'Cache-Control': 'no-store',
    },
    timeout: 90000,
  })
    .then((response) => {
      if (response.status === 401 && response.data.RequestError && tokenGenFailureCount < IH_MAX_RETRY_LIMIT) {
        tokenGeneration().then(function (response) {
          callingGatewayhtml(resolve, url, methodType, data);
        });
      } else if (
        response.status === 200 &&
        response.data !== null &&
        (!response.data.APIExceptionResponse || response.data.APIExceptionResponse === '')
      ) {
        tokenGenFailureCount && (tokenGenFailureCount = 0);
        resolve(response.data);
      } else tokenGenFailureCount && (tokenGenFailureCount = 0);
      resolve(response.data);
    })
    .catch((error) => {
      resolve(error);
    });
}

function processTokenResponse(response, resolve) {
  tokenDuration = parseInt(response.data['expires_in']) * 1000 || 0; // conv s to mS
  const access_token_issuedAt = parseInt(response.data['issued_at']);
  sameTokenIssued = !!(access_token_issuedAt === tokenUpdatedTime || access_token === response.data['access_token']);
  sameTokenIssued && (tokenGenFailureCount += 1);
  tokenUpdatedTime = access_token_issuedAt || tokenUpdatedTime; // epoch time in ms
  tokenValidUpto = tokenUpdatedTime + tokenDuration - 100000; // to renew new token 100s before it expires
  access_token = response.data['access_token'];
  const geoHdr = response.headers['x-ec-geohdr'];
  geoHdr &&
    (function (locationInfo) {
      const stateIdx = geoHdr.indexOf('region_code=');
      if (stateIdx > 0) {
        const stateString = geoHdr.slice(stateIdx + 12);
        const userState = stateString.slice(0, 2);
        window.sessionStorage.setItem('prefSt', userState);
      }
      const dmaIdx = geoHdr.indexOf('dma=');
      if (dmaIdx > 0) {
        const tmpdmaVal = geoHdr.slice(dmaIdx + 4);
        const dmaVal = tmpdmaVal.slice(0, 3);
        window.sessionStorage.setItem('dma', dmaVal);
      }
    })(geoHdr);
  if (lQVisitIdCount) {
    ++lQVisitIdCount;
    lQVisitIdCount === 10 && (lQVisitIdCount = -1) && window.sessionStorage.removeItem(IH_KEY_VST_EXPIRY_PATH);
  }
  resolve && resolve(response);
}

function newTokenGeneration(resolve) {
  let url = apiUrl().tokenGeneration;
  tokenGenFailureCount += 1;
  // sameTokenIssued && ( url = url.concat('?timestamp=' + JSON.stringify(Date.now())) );
  url = url.concat(TIMESTAMP_QPARM + JSON.stringify(Date.now()));
  axios({
    url: url,
    method: 'GET',
    responseType: 'json',
    withCredentials: true,
    cache: false,
    headers: TOKEN_HEADER,
    timeout: 90000,
  })
    .then((response) => {
      response && response.data && processTokenResponse(response, resolve);
    })
    .catch((error) => {
      resolve(error);
    });
}

async function fetchFreshToken() {
  let url = apiUrl().tokenGeneration;
  tokenGenFailureCount += 1;
  // sameTokenIssued && ( url = url.concat(TIMESTAMP_QPARM + JSON.stringify(Date.now())) );
  if (url) {
    url = url.concat(TIMESTAMP_QPARM + JSON.stringify(Date.now()));
    const response = await axios({
      url: url,
      method: 'GET',
      responseType: 'json',
      withCredentials: true,
      cache: false,
      headers: TOKEN_HEADER,
      timeout: 90000,
    });
    response && response.data && processTokenResponse(response);
  }
  return;
}

function getToken(cookies) {
  return getCookie('token', cookies);
}

function getCookie(cname, cookies) {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(cookies);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

access_token = getToken(document.cookie);

function getGlobalSessionName() {
  return INHOME_DOMAINS[hostName] || 'GlobalSessionID_awssit';
}

function getGlobalSessionID(cookies) {
  let gsmID = '';
  let gsmKey = getGlobalSessionName();
  if (cookies.indexOf(gsmKey) !== -1) {
    let slicedToken = cookies.slice(cookies.indexOf(gsmKey) + gsmKey.length);
    gsmID = slicedToken.slice(0, slicedToken.indexOf(';'));
    return gsmID.split('=')[1];
  }
  return gsmID;
}

export const getCookieVisitIdAndVisitorId = async () => {
  let generateCookieUrl = API_URL_GET_SERVER_VISIT_ID_AND_VISITOR_ID;

  const response = await axios.get(generateCookieUrl, {
    responseType: 'json',
    withCredentials: true,
    cache: false,
    headers: TOKEN_HEADER,
    timeout: 90000,
  });
  return response;
};

export const clearCookieVisitId = async () => {
  let clearCookieVisitIdUrl = API_URL_CLEAR_COOKIE_VISIT_ID;

  const response = await axios.get(clearCookieVisitIdUrl, {
    responseType: 'json',
    withCredentials: true,
    cache: false,
    headers: TOKEN_HEADER,
    timeout: 90000,
  });
  return response;
};
export default api;
