export const Pages = {
  Home: './accesserios',
  Category: '/home/accessories/',
  BeginCheckout: '/home/accessories/begincheckout/',
  ShoppingCart: '/home/accessories/shoppingcart/',
  gCheckOut: '/home/accessories/g/checkout/',
  sCheckOut: '/home/accessories/s/checkout/',
  gSubmitOrder: '/home/accessories/g/ordersubmit/',
  sSubmitOrder: '/home/accessories/s/ordersubmit/',
  Shop: '/home/accessories/shop/',
  LBO: '/home/accessories/lbo',
};

export const CheckOut = {
  onValidateCartSuccess: 'CO_VALIDATE_CART_SUCCESS',
  onValidateCartFailure: 'CO_VALIDATE_CART_FAILURE',
  tosChecked: 'TOS_CHECK',
  tosVoiceChecked: 'TOS_VOICE_CHECK',
  tosViewed: 'TOS_VIEWED',
  onNameValidation: 'ON_NAME_VALIDATION',
  updateContactInfo: 'UPDATE_CONTACT_INFO',
  updateNameInfo: 'UPDATE_NAME_INFO',
  onContactValidation: 'ON_CONTACT_VALIDATION',
  updateBillingAddrInfo: 'UPDATE_BILLING_ADDR_INFO',
  onBillingAddressValidation: 'ON_BILLING_ADDRESS_VALIDATION',
  onUpdateExternalCreditCheck: 'UPDATE_EXTERNAL_CREDITCHECK',
  noSSNCheck: 'NO_SSN_CHECK',
  onUpdateCreditCheck: 'UPDATE_CREDITCHECK',
  onDueDateSuccess: 'ON_DUEDATE_SUCCESS',
  resetDueDate: 'RESET_DUEDATE',
  resetDueDateMsg: 'RESET_DUEDATE_MESSAGE',
  updateStorePickUpData: 'UPDATE_STOREPICKUP_DATA',
  updatePackageLockerData: 'UPDATE_PACKAGELOCKER_DATA',
  onUpdateCreditCheckStatus: 'UPDATE_CREDITCHECK_STATUS',
  updatePersonalInfoAccordionStatus: 'UPDATE_PERSONALINFO_ACCOR_STATUS',
  updateSatelliteAccordionStatus: 'UPDATE_SATELLITE_ACCOR_STATUS',
  updateCreditCheckAccordionStatus: 'UPDATE_CREDITCHECK_ACCOR_STATUS',
  updateInstallationAccordionStatus: 'UPDATE_INSTALLATION_ACCOR_STATUS',
  updateAutoPayAccordionStatus: 'UPDATE_AUTOPAY_ACCOR_STATUS',
  updateTosAccordionStatus: 'UPDATE_TOS_ACCOR_STATUS',
  updateDepositAccordionStatus: 'UPDATE_DEPOSIT_ACCOR_STATUS',
  onSetDueDate: 'ON_SET_DUEDATE',
  updateRTAQs: 'UPDATE_RTA_Qs',
  updateRTAChoice: 'UPDATE_CHOICE',
  updateRTAStatus: 'UPDATE_RTA_STATUS',
  updateDepositInfo: 'UPDATE_DEPOSIT_INFO',
  updateDepositAmount: 'UPDATE_DEPOSIT_Amount',
  updateFinalBillStatus: 'UPDATE_FINAL_BILL_STATUS',
  updateCRVNunmber: 'UPDATE_CRV_NO',
  updateInitialState: 'UPDATE_INITIAL_STATE',
  updateCreditFailAttempts: 'UPDATE_CREDIT_FAIL_ATTEMPTS',
  updateFinalBillAttempts: 'UPDATE_FINAL_BILL_ATTEMPTS',
  updateSameDayDelivery: 'UPDATE_SAMEDAY_DELIVERY',
  updateSSNandDOBStatus: 'UPDATE_SSN_DOB_STATUS',
  mobtnType: 'Mobile',
  isOnLoadBillingAddressValidation: 'IS_ONLOAD_BILLING_ADDRESS_VALIDATION',
  satelliteEligible: 'SATELLITE_ELIGIBLE',
  satelliteOption: 'SATELLITE_OPTION',
  satelliteClick: 'SATELLITE_CLICK',
  updateBAUCC: 'UPDATE_BAU_CC',
  PrevSelectedProd: 'PREV_SELECTED_PROD',
  checkoutLoaded: 'CHECKOUT_LOADED',
  contactInfoValidationUpdate: 'CONTACT_INFO_VALIDATION_UPDATE',
  mvCheckout: 'MOVE_CHECKOUT',
  returnEquipmentOption: 'RETURN_ENQUIPMENT_OPTION',
  returnEquipmentClick: 'RETURN_ENQUIPMENT_CLICK',
  updateReturnEquipAccordianStatus: 'UPDATE_RETURN_ENQUIPMENT_ACCORDIAN_STATUS',
};

export const Service = {
  internet: 'INTERNET',
  router: 'ROUTER',
  customRouter: 'CUSTOMROUTER',
  techSure: 'TECHSURE',
  vas: 'PPP',
  fne: 'FNE',
  tv: 'TV',
  voice: 'FIOS_VOICE',
  hsi: 'HSI',
  vRoom: 'VROOM',
  recording: 'RECORDING',
  listing: 'LISTING',
  battery: 'BATTERY',
  batteryshipment: 'BBUSHIPMENT',
  channel: 'CHANNEL',
  winBack: 'WINBACK',
  customTV: 'CUSTOMTV',
  winBackNo: 'WINBACKNO',
  data: 'DATA',
  video: 'VIDEO',
  dct: 'DCT',
  FiOSData: 'FIOSDATA',
  FiOSVoice: 'VOICE',
  customGeneric: 'CUSTOM_GENERIC',
  splOffer: 'SPLOFFER',
  flashOffer: 'FLASHOFFER',
  streamServ: 'OTTBASIC',
  streamDev: 'OTTEQUIPNRC',
  streamDevCount: 'STREAMDEVCNT',
  streamDevCountResume: 'STREAMDEVCNTRESUME',
  youtubeReferal: 'YOUTUBE_REFERAL',
  youtube: 'YOUTUBE',
  fiosFive: 'FIOSFIVE',
  vasstandalone: 'VASSTANDALONE',
};

export const PAGEACTIVITY = {
  PAGE: {
    LQ: 'LOOPQUAL',
    BYOB: 'BUILDPRODUCT',
    CO: 'CHECKOUT',
    CART: 'CART',
    RC: 'RETRIEVECART',
    SMRY: 'SUMMARY',
    CI: 'CONTACTINFO',
    ASYNC: 'ASYNCAPI',
    CC: 'CREDITCHECK',
    INST: 'INSTALLATION',
    PFB: 'PAPERFREEBILL',
    AP: 'AUTOPAY',
    TOS: 'TERMSOFSERVICE',
    DP: 'DEPOSIT',
    PO: 'PLACEORDER',
    RTA: 'RTA',
    FB: 'FINALBILL',
    STV: 'SATELLITETV',
    CARE: 'CAREFLOW',
    SD: 'SUPPDETAIL',
  },
  MSG: {
    ZipNotFound: 'ZIPNOTFOUND',
    StreetNotFound: 'STREETNOTFOUND',
    AddressNotFound: 'ADDRESSNOTFOUND',
    unitCount: 'UNITCOUNT',
    NoUnit: 'NOUNIT',
    mtnEntered: 'MTNENTERED',
    emailEntered: 'EMAILENTERED',
    RememberMe: 'REMEMBERME',
    HOA: 'HOA',
    FIVEG: 'FIVEG',
    redirectingBAU: 'REDIRECTINGBAU',
    revisit: 'REVISITCUSTOMER',
    changeAdr: 'CHANGEADDRESS',
    MultipleAddress: 'MULTIPLEADDRESS',
    lqRetry: 'LQRETRY',
    lqDataNullRetry: 'LQDATANULLRETRY',
    lqDataNullProceed: 'LQDATANULLPROCEED',
    LocusError: 'LOCUSERROR',
    InsideCheckAddr: 'INSIDECHECKADDR',
    CheckLQBtnClick: 'CHECKLQBTNCLICK',
    ExistingService: 'EXISTINGSERVICEFOUND',
    SmartCartFound: 'SMARTCARTFOUND',
    ContinueSmartCart: 'SMARTCARTFOUND-CONTINUE',
    ContinueNewOrder: 'SMARTCARTFOUND-NEW',
    PendingOrder: 'PENDINGORDERFOUND',
    PendingOrderYes: 'PENDINGORDERFOUND-YES',
    PendingOrderNo: 'PENDINGORDERFOUND-NO',
    CantFindAddress: 'CANTFINDADDRESS',
    StreetNotFoundinLocus: 'STREETNOTFOUNDINLOCUS',
    StreetfoundinNtas: 'STREETFOUNDINNTAS',
    StreetnotfoundinNtas: 'STREETNOTFOUNDINNTAS',
    CantFindUnit: 'CANTFINDUNIT',
    HelpClick: 'HELPCLICK',
    SigninClick: 'SIGNINCLICK',
    OOFClick: 'OOFCLICK',
    fiveGZip: 'FIVEGZIP',
    OneLQFlow: 'ONELQFLOW',
    VisitIdEmpty: 'REDIRECTED-AS-VISITIDEMPTY',
    CaptchaShown: 'CAPTCHASHOWN',
    CaptchaSuccess: 'CAPTCHASUCCESS',
    CaptchaFailure: 'CAPTCHAFAILURE',
    OneLqFiosQual: 'ONELQFIOSQUAL',
    OneLq5GQual: 'ONELQ5G',
    OneLqredirectingBAU: 'ONELQREDIRECTINGBAU',
    AddressLength: 'ADDRESSLENGTH',
    OneLqNonFDVEligible: 'ONELQ-FIOS-NONFDV',
    NonFDVEligible: 'FIOS-NONFDV',
    lqError: 'LQERROR',
    productNotFound: 'PRODUCTNOTFOUND',
    productFound: 'PRODUCTFOUND',
    apiResponseFailed: 'APIRESPONSEFAILED',
    error_404: 'ERROR_404',
    responseEmpty: 'RESPONSEEMPTY',
    notAvailable: 'NOTAVAILABLE',
    available: 'AVAILABLE',
    m2m: 'M2M',
    twoyear: '2YR',
    CCCompleted: 'CCCOMPLETED',
    personalInfoCompleted: 'PERSONALINFOCOMPLETED',
    showLBO: 'SHOWLBO',
    couponInfoExists: 'COUPONINFOEXISTS',
    retrieveFailedCart: 'RETRIEVEFAILED-CART',
    resumeMONError: 'RESUMEMONERROR',
    resumeWB: 'RESUMEWINBACKTN',
    resumeDepositRequired: 'RESUMEDEPOSITREQUIRED',
    retrieveCartResponseEmptyorNull: 'RETRIEVECART-RESPONSEEMPTYORNULL',
    retrieveCartDataNull: 'RETREIVECART-DATANULL',
    redirectToCheckout: 'REDIRECTTOCHECKOUT',
    redirectToBuildProducts: 'REDIRECTTOBUILDPRODUCTS',
    cartEmpty: 'CARTEMPTY',
    cartResponseNull: 'CARTRESPONSE-NULL',
    cartServiceNull: 'CARTSERVICE-NULL',
    LD: 'LOADED',
    CD: 'COMPLETED',
    lboRedirectOOF: 'LBOREDIRECTOOF',
    changeinadrId: 'CHANGEINADRID',
    suppBuildPrdBack: 'SUPPBUILDPRD-BACK',
  },
  UA: 'USERACTION',
};
export const mvaNavigationPattern = {
  patternB: 'B',
  patternD: 'D',
};
