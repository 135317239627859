import React, { Suspense, lazy } from 'react';
// import { BrowserRouter, Switch, Route } from 'react-router-dom';
//import asyncComponent from './../components/asyncComponent';

import { Route, Switch } from 'react-router';
import ErrorBoundary from '../service/errorBoundary';
// import Accessories from './../containers/accessories/index';
// import WrapperProduct from '../containers/wrapper/wrapperProduct';
// import WrapperProductDetail from './../containers/wrapper/wrapperProductDetails';
// import beginCheckout from './../containers/beginCheckout/index';
// import Checkout from './../containers/checkout/index';
// import ShopCart from '../containers/Cart/shoppingCart/index';
const Accessories = lazy(() => import('./../containers/accessories/index'));
const WrapperProduct = lazy(() => import('../containers/wrapper/wrapperProduct'));
//const WrapperProductDetail = lazy(() => import('./../containers/wrapper/wrapperProductDetails'));
const beginCheckout = lazy(() => import('./../containers/beginCheckout/index'));
const Checkout = lazy(() => import('./../containers/checkout/index'));
const ShopCart = lazy(() => import('../containers/Cart/shoppingCart/index'));
const orderSubmit = lazy(() => import('../containers/orderSubmit/index'));
const lbo = lazy(() => import('./../containers/lbo/index'));
const custError = lazy(() => import('./../components/custError'));
const cacheRefresh = lazy(() => import('./../components/cacheRefresh'));
const shop = lazy(() => import('./../containers/mva/index'));
// import Accessories from './../containers/accessories/index';
// import WrapperProduct from './../containers/wrapper/wrapperProduct';
// import WrapperProductDetail from './../containers/wrapper/wrapperProductDetails';
// import Test from './../containers/test/index.js';

// const Accessories = asyncComponent(() =>
//     import('./../containers/accessories/index').then(module => module.default)
// )
// const WrapperProduct = asyncComponent(() =>
//     import('../containers/wrapper/wrapperProduct').then(module => module.default)
// )
// const WrapperProductDetail = asyncComponent(() =>
//     import('./../containers/wrapper/wrapperProductDetails').then(module => module.default)
// )
// const beginCheckout = asyncComponent(() =>
//     import('./../containers/beginCheckout/index').then(module => module.default)
// )
// const Checkout = asyncComponent(() =>
//     import('./../containers/checkout/index').then(module => module.default)
// )
// const ShopCart = asyncComponent(() =>
//     import('../containers/Cart/shoppingCart/index').then(module => module.default)
// )
// const orderSubmit = asyncComponent(() =>
//     import('../containers/orderSubmit/index').then(module => module.default)
// )

const routes = (
  <React.Fragment>
    {/* <BrowserRouter> */}
    <ErrorBoundary>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/home/accessories" component={Accessories} />
          <Route exact path="/home/accessories/shop/:lob" component={shop} />
          <Route exact path="/home/accessories/begincheckout" component={beginCheckout} />
          <Route exact path="/home/accessories/g/ordersubmit" component={orderSubmit} />
          <Route exact path="/home/accessories/s/ordersubmit" component={orderSubmit} />
          <Route exact path="/home/accessories/g/checkout" component={Checkout} />
          <Route exact path="/home/accessories/s/checkout" component={Checkout} />
          <Route exact path="/home/accessories/lbo" component={lbo} />
          <Route exact path="/home/accessories/shoppingcart" component={ShopCart} />
          <Route exact path="/home/accessories/cacherefresh" component={cacheRefresh} />
          <Route exact path="/home/accessories/:pageName" component={WrapperProduct} />
          <Route exact path="/home/accessories/:pageName/:compatibility" component={WrapperProduct} />
          <Route exact path="/home/accessories/:pageName/:filter/:filterName" component={WrapperProduct} />
          {/* <Route exact path="/home/accessories/:pageName/:subPageName" component={WrapperProductDetail} /> */}
          <Route path="*" component={custError} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
    {/* </BrowserRouter>  */}
  </React.Fragment>
);

export default routes;
