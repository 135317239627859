const initialState = {
  isValidUser: false,
  isProfileCall: false,
  isGuestFlow: false,
  isSignApplied: false,
};
function categories(state = initialState, action) {
  switch (action.type) {
    case 'CATEGORIESFROMAPI':
      return Object.assign({}, state, {
        categoriesExp: action.value,
      });
    case 'BANNERFROMAPI':
      return Object.assign({}, state, {
        bannerExp: action.value,
      });
    case 'FEATUREDACCESSORIESFROMAPI':
      return Object.assign({}, state, {
        featuredAccessoriesExp: action.value,
      });
    case 'STORECATEGORYID': {
      //  Object.assign( ...state, {
      //     categoryId: action.value,
      //  })
      //  return state;
      // }
      return { ...state, categoryDetails: action.value };
    }
    case 'UPDATEPAGENAME': {
      //  Object.assign( ...state, {
      //     categoryId: action.value,
      //  })
      //  return state;
      // }
      return { ...state, pageName: action.value };
    }
    case 'VALIDUSER': {
      return Object.assign({}, state, {
        isValidUser: action.value,
      });
    }
    case 'PROFILECALL': {
      return Object.assign({}, state, {
        isProfileCall: action.value,
      });
    }
    case 'ISGUESTFLOW': {
      return Object.assign({}, state, {
        isGuestFlow: action.value,
      });
    }
    case 'ISSIGNINAPPLIED': {
      return Object.assign({}, state, {
        isSignApplied: action.value,
      });
    }
    case 'STANDARDERRORSFROMAPI': {
      return { ...state, standardErrors: action.value };
    }

    default:
      return state;
  }
}
export default categories;
