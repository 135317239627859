import React, { Suspense } from 'react';
import './App.css';
import VZWebLib from './myvzweb-lib';
import routes from './routes/index';
import routesSMB from './routesSMB/index';
import { ConnectedRouter } from 'connected-react-router';
import { CookiesProvider } from 'react-cookie';
import { createBrowserHistory } from 'history';
import GlobalHeader from './components/globalHeader';
import GlobalFooter from './components/globalFooter';
import $ from 'jquery';
export const history = createBrowserHistory();

const loadingImg = (
  <div className="page-overlay active">
    <div className="section-overlay">
      <div className="row section-loader vert-middle margin-vert-zero fluid-grid">
        <div className="column padding-vert-zero section-overlay-column">
          <div className="margin-top-small text-medium bold" role="alert" aria-live="assertive">
            <div className="spin-loader"></div>
            <div className="loader-messgae">Loading...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
let queryString = window.location.search;
let fiosAPPrequest = queryString.indexOf('myfios') > -1;
if (fiosAPPrequest) {
  window.localStorage.setItem('myFios', true);
}
let AppBody;
let AppName = window.location.pathname;
let mvaAppRequest = AppName.indexOf('/home/accessories/shop/') > -1;
if (mvaAppRequest) {
  window.localStorage.setItem('mvaShop', true);
}
if (AppName.indexOf('home') > -1) {
  if (window.localStorage.myFios || AppName.indexOf('cacherefresh') > -1 || window.localStorage.mvaShop) {
    AppBody = <React.Fragment>{routes}</React.Fragment>;
  } else {
    AppBody = (
      <React.Fragment>
        <div id="vz-gh20"></div>
        {routes}
        <div id="vz-gf20"></div>
      </React.Fragment>
    );
  }
} else if (AppName.indexOf('smallbusiness') > -1) {
  if (window.localStorage.myFios || AppName.indexOf('cacherefresh') > -1) {
    AppBody = <React.Fragment>{routesSMB}</React.Fragment>;
  } else {
    AppBody = (
      <React.Fragment>
        <div id="vz-gh20"></div>
        {routesSMB}
        <div id="vz-gf20"></div>
      </React.Fragment>
    );
  }
}
const App = ({ history }) => {
  return (
    <Suspense fallback={loadingImg}>
      <ConnectedRouter history={history}>
        <CookiesProvider>{AppBody}</CookiesProvider>
      </ConnectedRouter>
    </Suspense>
  );
};

export default App;
