export const nativeSetTitle = (title) => {
  var tMsg = { actionType: 'setTitle', title: title };
  invokeNative(JSON.stringify(tMsg));
};
/**hide cart/wishlist */
export const hideWishListCart = () => {
  var pgMsg = {
    hideShopCart: true,
    hideWishList: true,
  };
  invokeNative(JSON.stringify(pgMsg));
};
/**show popup */
/*export const popupModal = (title, alertMsg,bckBtnTxt,continueBtnTxt) => {
  var popupMsg = {
    actionType: "updateNavigation",
    navigationBar: {
        moleculeName: "navigationBar",
        alwaysShowBackButton: true,
        title: title,
        hideTopTabBar: true,
        backButton: {
            image: "nav_close",
            moleculeName: "navigationImageButton",
            action: {
                actionType: "alert",
                alert: {
                    title: "",
                    message: alertMsg,
                    alertActions: [
                        {
                            title:bckBtnTxt,
                            action: {
                                actionType: "back",
                                analyticsData: {
                                    "vzwi.mvmapp.LinkName": "close"
                                },
                                pageType: "back",
                                presentationStyle: "push",
                                title: "Close"
                            }
                        },
                        {
                            title: continueBtnTxt,
                            style: "cancel",
                            action: {
                                "actionType": "cancel"
                            }
                        }
                    ]
                }
            },
            accessibilityText: "Close"
        }
    }
}

  invokeNative(JSON.stringify(popupMsg));
}*/
/**hide button tab bar for fios accessories flow */
export const hideBtmTabBar = () => {
  var pgMsg = {
    actionType: 'updateTab',
    tab: { tabBarHidden: true },
  };
  invokeNative(JSON.stringify(pgMsg));
};
export const nativeBackActionWithCustomPageType = (title, pageName, extraParameters = {}) => {
  var tMsg = {
    actionType: 'updateNavigation',
    navigationBar: {
      moleculeName: 'navigationBar',
      title: title,
      alwaysShowBackButton: true,
      backButton: {
        moleculeName: 'navigationImageButton',
        image: 'nav_back',
        accessibilityText: 'back',
        action: { title: 'back', actionType: 'openPage', pageType: pageName, presentationStyle: 'replace', extraParameters },
      },
    },
  };
  invokeNative(JSON.stringify(tMsg));
};

export const nativeShowPDF = (pdf) => {
  const args = { actionType: 'openPDF', pdfString: pdf.data, type: pdf.type };
  invokeNative(JSON.stringify(args));
};

/*export const setNativeTitleWithNavigation = (title, showButton, showBackButton = false, isLanding, mvaType, isProductInCart) => {
  var tMsg;
  if (showBackButton) {
    if(isLanding && (mvaType == 'vzw') && isProductInCart){
      var tMsg = {
        actionType: "updateNavigation",
        navigationBar: {
            moleculeName: "navigationBar",
            alwaysShowBackButton: true,
            title: title,
            hideTopTabBar: true,
            backButton: {
                image: "nav_close",
                moleculeName: "navigationImageButton",
                action: {
                    actionType: "alert",
                    alert: {
                        title: "",
                        message: "Once you leave, your cart will be emptied",
                        alertActions: [
                            {
                                title:"Leave cart",
                                action: {
                                    actionType: "back",
                                    analyticsData: {
                                        "vzwi.mvmapp.LinkName": "close"
                                    },
                                    pageType: "feedsAuthenticatedWebView",// "back",
                                    presentationStyle: "push",
                                    title: "Close"
                                }
                            },
                            {
                                title: "Return to cart",
                                style: "cancel",
                                action: {
                                    "actionType": "cancel"
                                }
                            }
                        ]
                    }
                },
                accessibilityText: "Close"
            }
          }
        }
    }else{
    tMsg = {
      actionType: 'updateNavigation', navigationBar: {
        moleculeName: "navigationBar", 
        alwaysShowBackButton: showButton,
         title: title, 
         hideTopTabBar: true, 
         backButton: {
          image: "nav_back",
          moleculeName: "navigationImageButton",
          action: {
            actionType: "back",
            analyticsData: {
              "vzwi.mvmapp.LinkName": "close"
            },
            pageType: "back",
            presentationStyle: "push",
            title: "Close"
          },
          accessibilityText: "Back"//"Close"
        }
      }
    }
  }
  } else {
    tMsg = {

      actionType: 'updateNavigation', navigationBar: { moleculeName: "navigationBar", alwaysShowBackButton: showButton, title: title, hideTopTabBar: true, line: { moleculeName: "line", "type": "none" } }
    }
  }
  invokeNative(JSON.stringify(tMsg));
};
*/
export const setNativeTitleWithNavigation = (title, showBackButton, isProductInCart, isLanding, reqUrl) => {
  var tMsg;
  if (showBackButton) {
    if (isLanding && isProductInCart) {
      tMsg = showPopupModalNavigation(title, reqUrl);
    } else {
      tMsg = {
        actionType: 'updateNavigation',
        navigationBar: {
          moleculeName: 'navigationBar',
          alwaysShowBackButton: showBackButton,
          title: title,
          hideTopTabBar: true,
          backButton: {
            image: 'nav_back',
            moleculeName: 'navigationImageButton',
            action: {
              actionType: 'back',
              analyticsData: {
                'vzwi.mvmapp.LinkName': 'close',
              },
              pageType: 'back',
              presentationStyle: 'push',
              title: 'Close',
            },
            accessibilityText: 'Back', //"Close"
          },
        },
      };
    }
  } else {
    tMsg = {
      actionType: 'updateNavigation',
      navigationBar: {
        moleculeName: 'navigationBar',
        alwaysShowBackButton: showBackButton,
        title: title,
        hideTopTabBar: true,
        line: { moleculeName: 'line', type: 'none' },
      },
    };
  }
  invokeNative(JSON.stringify(tMsg));
};
/**show popupModal With Nacigation */
export const showPopupModalNavigation = (title, reqUrl) => {
  let tMsg = '';
  tMsg = {
    actionType: 'updateNavigation',
    navigationBar: {
      moleculeName: 'navigationBar',
      alwaysShowBackButton: true,
      title: title,
      hideTopTabBar: true,
      backButton: {
        image: 'nav_close',
        moleculeName: 'navigationImageButton',
        action: {
          actionType: 'alert',
          alert: {
            title: '',
            message: 'Fios items are only available in the Fios Shop Flow.',
            alertActions: [
              {
                title: 'Leave Fios Shop',
                action: {
                  actionType: 'openPage',
                  analyticsData: {
                    'vzwi.mvmapp.LinkName': 'close',
                  },
                  pageType: 'feedsAuthenticatedWebView', // "back",
                  presentationStyle: 'root',
                  requestURL: reqUrl,
                  title: 'Close',
                  extraParameters: {
                    browserUrl: 'sales/next/shop.html?isShopFlow=true&entrypoint=tabbar',
                    locale: 'EN',
                  },
                },
              },
              {
                title: 'Remain in Fios Shop',
                style: 'cancel',
                action: {
                  actionType: 'cancel',
                },
              },
            ],
          },
        },
        accessibilityText: 'Close',
      },
    },
  };
  return tMsg;
};
/**navigation pattern branding changes pattern A*/
export const setTitleWithNavigationPatternA = () => {
  var tMsg = {
    actionType: 'updateNavigation',
    navigationBar: {
      moleculeName: 'navigationBar',
      additionalRightButtons: [
        {
          image: 'nav_stores_white',
          moleculeName: 'navigationImageButton',
          action: {
            actionType: 'openPage',
            analyticsData: {
              'vzdl.page.linkName': 'global tab nav:stores',
            },
            pageType: 'rtlStoreJourney',
            title: 'Stores',
          },
          accessibilityText: 'Stores',
        },
      ],
      additionalLeftButtons: [
        {
          image: 'nav_vz_mark',
          moleculeName: 'navigationImageButton',
          imageRenderingMode: 'alwaysOriginal',
          action: {
            actionType: 'scrollToTop',
          },
          accessibilityText: 'Verizon logo button, tap anytime to scroll to top of page',
        },
      ],
      style: 'dark',
    },
  };
  invokeNative(JSON.stringify(tMsg));
};
export const setTitleWithNavigationPattern = (title, showBackButton, btnImage, isProductInCart, isLanding, reqUrl) => {
  if (showBackButton) {
    var tMsg;
    if (isLanding && isProductInCart) {
      tMsg = showPopupModalNavigation(title, reqUrl);
    } else {
      tMsg = {
        actionType: 'updateNavigation',
        navigationBar: {
          moleculeName: 'navigationBar',
          alwaysShowBackButton: showBackButton,
          title: title,
          style: 'light',
          backButton: {
            image: btnImage, // "nav_back",
            moleculeName: 'navigationImageButton',
            action: {
              actionType: 'back',
              analyticsData: {
                'vzwi.mvmapp.LinkName': 'close',
              },
              pageType: 'back',
              presentationStyle: 'push',
              title: 'Close',
            },
            accessibilityText: 'Close',
          },
        },
      };
    }
  } else {
    tMsg = {
      actionType: 'updateNavigation',
      navigationBar: {
        moleculeName: 'navigationBar',
        alwaysShowBackButton: showBackButton,
        title: title,
        hideTopTabBar: true,
        line: { moleculeName: 'line', type: 'none' },
      },
    };
  }
  console.log('tMsg', tMsg);
  invokeNative(JSON.stringify(tMsg));
};

/**hide button tab bar for fios accessories flow */
export const showHideBtmTabBar = (btnTabBarFlag) => {
  var pgMsg = {
    actionType: 'updateTab',
    tab: { tabBarHidden: btnTabBarFlag }, // true
  };
  invokeNative(JSON.stringify(pgMsg));
};
/**hide cart/wishlist */
export const showHideWishListCart = (shopCartFlag, wishListFlag) => {
  var pgMsg = {
    hideShopCart: shopCartFlag, // true,
    hideWishList: wishListFlag, //true
  };
  invokeNative(JSON.stringify(pgMsg));
};
export const nativeTimePicker = () => {
  const args = { actionType: 'timePickerAction', jsCallback: 'timePickerEvent' };
  invokeNative(JSON.stringify(args));
};

export const nativeOnBack = () => {
  var tMsg = { actionType: 'back' };
  invokeNative(JSON.stringify(tMsg));
};

const getBannerColorInfo = (banner) => {
  let type = 'GlobalError',
    messageColor = '#000000',
    topAlertColor = '#ED7000';
  if (banner.type === 'success') {
    type = 'Success';
    messageColor = '#FFFFFF';
    topAlertColor = '#008900';
  } else if (banner.type === 'warning') {
    type = 'Success';
    topAlertColor = '#FFBC3D';
  }
  return { type, messageColor, topAlertColor };
};

export const nativeSetAlert = (banner) => {
  var errMsg = {};
  errMsg.actionType = 'topAlert';
  errMsg.buildNumber = 207;
  errMsg.buttonTitle = 'View';
  errMsg.code = '00000';
  errMsg.locale = 'en';
  //errMsg.message = errMessage;
  errMsg.messageStyle = 'Top';
  errMsg.requestId = 'f01926d1-db5f-4035-84d7-2c7eeb4ecd0f';
  //errMsg.topMessage = errTitle;
  errMsg.type = 'success';
  errMsg.topAlertColor = '#007AB8';
  errMsg.userMessage = errMessage;
  errMsg.newTopAlertStyle = true;
  invokeNative(JSON.stringify(errMsg));
};

export const nativeSetError = (errTitle, errMessage, colorCode, isPersist = true) => {
  var errMsg = {};
  errMsg.actionType = 'topAlert';
  errMsg.buildNumber = 207;
  errMsg.buttonTitle = 'View';
  errMsg.code = '00000';
  errMsg.locale = 'en';
  errMsg.message = errMessage;
  errMsg.messageStyle = isPersist ? 'TopPersistent' : 'Top';
  errMsg.topAlertColor = colorCode || '#007ab8';
  errMsg.requestId = 'f01926d1-db5f-4035-84d7-2c7eeb4ecd0f';
  errMsg.topMessage = errTitle;
  errMsg.type = 'ErrorScreen';
  errMsg.userMessage = errMessage;
  invokeNative(JSON.stringify(errMsg));
};

export const nativeSetBlueAlert = (errMessage) => {
  var errMsg = {};
  errMsg.actionType = 'topAlert';
  errMsg.buildNumber = 207;
  errMsg.buttonTitle = 'View';
  errMsg.code = '00000';
  errMsg.locale = 'en';
  //errMsg.message = errMessage;
  errMsg.messageStyle = 'Top';
  errMsg.requestId = 'f01926d1-db5f-4035-84d7-2c7eeb4ecd0f';
  //errMsg.topMessage = errTitle;
  errMsg.type = 'success';
  errMsg.topAlertColor = '#007ab8';
  errMsg.userMessage = errMessage;
  errMsg.newTopAlertStyle = true;
  invokeNative(JSON.stringify(errMsg));
};

export const nativeSetBlueAlertWithAction = (msgTitle, msgText, buttonText, actionLink) => {
  var errMsg = {};
  errMsg.actionType = 'topAlert';
  errMsg.buildNumber = 207;
  errMsg.buttonTitle = 'View';
  errMsg.code = '00000';
  errMsg.locale = 'en';
  errMsg.message = msgText;
  errMsg.messageStyle = 'TopPersistent';
  errMsg.requestId = 'f01926d1-db5f-4035-84d7-2c7eeb4ecd0f';
  //errMsg.topMessage = msgTitle;
  errMsg.type = 'Success';
  errMsg.topAlertColor = '#007ab8';
  errMsg.userMessage = ''; //msgText;
  errMsg.newTopAlertStyle = true;
  errMsg.closeButton = true;
  errMsg.ButtonMap = {};
  errMsg.ButtonMap.appContext = 'mobileFirstSS';
  errMsg.ButtonMap.analyticsData = {};
  errMsg.ButtonMap.disableAction = false;
  errMsg.ButtonMap.tryToReplaceFirst = false;
  errMsg.ButtonMap.openInWebview = true;
  errMsg.ButtonMap.actionType = 'openURL';
  errMsg.ButtonMap.hideUrl = false;
  errMsg.ButtonMap.pageType = 'mixAndMatchExplorePlanSelector';
  errMsg.ButtonMap.presentationStyle = 'push';
  errMsg.ButtonMap.title = buttonText;
  errMsg.ButtonMap.isSelected = false;
  errMsg.ButtonMap.selected = false;
  (errMsg.ButtonMap.openOauthWebView = true),
    (errMsg.ButtonMap.customUserAgent = 'MY_VZW_APP;IOS13.3;iPhone12,1'),
    (errMsg.ButtonMap.checkCameraPermission = false),
    (errMsg.ButtonMap.showNativeNavigation = true),
    (errMsg.ButtonMap.openInWebview = true),
    (errMsg.ButtonMap.browserUrl = actionLink),
    invokeNative(JSON.stringify(errMsg));
};

export const nativeSetNotificationWithAction = (msgText, buttonText, pagetype, colorCode) => {
  var errMsg = {};
  errMsg.actionType = 'topAlert';
  errMsg.buildNumber = 207;
  errMsg.buttonTitle = 'View';
  errMsg.code = '00000';
  errMsg.locale = 'en';
  errMsg.message = '';
  errMsg.messageStyle = 'Top';
  errMsg.requestId = 'f01926d1-db5f-4035-84d7-2c7eeb4ecd0f';
  //errMsg.topMessage = msgTitle;
  errMsg.type = 'Success';
  errMsg.topAlertColor = colorCode || '#007ab8';
  errMsg.userMessage = msgText;
  errMsg.newTopAlertStyle = true;
  errMsg.closeButton = true;
  errMsg.ButtonMap = {};
  errMsg.ButtonMap.appContext = 'mobileFirstSS';
  errMsg.ButtonMap.analyticsData = {};
  errMsg.ButtonMap.disableAction = false;
  errMsg.ButtonMap.tryToReplaceFirst = false;
  errMsg.ButtonMap.openInWebview = true;
  errMsg.ButtonMap.actionType = 'openPage';
  errMsg.ButtonMap.hideUrl = false;
  errMsg.ButtonMap.pageType = pagetype;
  errMsg.ButtonMap.presentationStyle = 'push';
  errMsg.ButtonMap.title = buttonText;
  errMsg.ButtonMap.isSelected = false;
  errMsg.ButtonMap.selected = false;
  (errMsg.ButtonMap.openOauthWebView = false),
    (errMsg.ButtonMap.customUserAgent = 'MY_VZW_APP;IOS13.3;iPhone12,1'),
    (errMsg.ButtonMap.checkCameraPermission = false),
    (errMsg.ButtonMap.showNativeNavigation = true),
    (errMsg.ButtonMap.openInWebview = false),
    invokeNative(JSON.stringify(errMsg));
};

export const nativeRedirectToPage = (pageType, title) => {
  var pgMsg = {};
  pgMsg.actionType = 'openPage';
  pgMsg.title = title;
  pgMsg.presentationStyle = 'push';
  pgMsg.tryToReplaceFirst = false;
  pgMsg.disableAction = false;
  pgMsg.pageType = pageType;
  pgMsg.appContext = 'mobileFirstSS';
  pgMsg.extraParameters = { requestFrom: 'NSANBS' };
  invokeNative(JSON.stringify(pgMsg));
};

export const nativeRedirectToExternalBrowser = (browserUrl, title) => {
  var pgMsg = {};
  pgMsg.actionType = 'openURL';
  pgMsg.title = title;
  pgMsg.presentationStyle = 'push';
  pgMsg.tryToReplaceFirst = false;
  pgMsg.disableAction = false;
  pgMsg.pageType = 'goTradeInLanding';
  pgMsg.openInWebview = true;
  pgMsg.openOauthWebView = false;
  pgMsg.browserUrl = browserUrl;
  pgMsg.appContext = 'mobileFirstSS';
  invokeNative(JSON.stringify(pgMsg));
};

export const nativeHideTopBar = (flag) => {
  var pgMsg = { actionType: 'updateTopTabs', topTabs: { topTabsHidden: flag } };
  invokeNative(JSON.stringify(pgMsg));
};

export const nativeCardScan = (callback) => {
  const args = {
    actionType: 'creditCardScan',
    presentationStyle: 'modal',
    page: {
      pageType: 'scanCCPage',
      parentPageType: 'gridwall',
      flowType: 'multiline',
      flowName: 'nao',
      ButtonMap: {
        SecondaryButton: {
          presentationStyle: 'push',
          analyticsData: {},
          analyticsReqData: {},
          pageType: 'backButton',
          appContext: 'mobileFirstSS',
          actionType: 'back',
          title: 'Cancel',
        },
      },
      title: 'Position your card into frame and it will scan automatically.',
      screenHeading: 'Add Credit or Debit Card',
      atmScreenHeading: 'Add ATM Card',
    },
    jsCallback: 'js.window',
  };
  invokeNative(JSON.stringify(args));
};

export const nativeEditCalendarEvent = (args) => {
  invokeNative(JSON.stringify({ actionType: 'calendarEditEvent', title: args, jsCallback: 'editEventNotify' }));
};

export const nativeCalendarEventExist = (args) => {
  invokeNative(JSON.stringify({ actionType: 'calendarEventExist', title: args, jsCallback: 'calenderEventExist' }));
};

export const nativeCalendarAccessAllowed = () => {
  invokeNative(JSON.stringify({ actionType: 'calendarEventAccess', jsCallback: 'calendarEventAccess' }));
};

export const nativeCalendarAddEvent = (args) => {
  let mNotes = args.eventMessage1 + ' ' + args.dueDate + args.eventMessage2 + '\n' + args.urlWithDeeplink;
  invokeNative(
    JSON.stringify({
      actionType: 'calendarAddEvent',
      eventTitle: args.eventTitle,
      notes: mNotes,
      eventStartDate: args.eventStartDate,
      timeZone: args.timeZone,
      successMessage: args.successMessage,
      jsCallback: 'calendarBillAddEvent',
    }),
  );
};

var invokeNative = function (msg) {
  console.log('invokeNative called.');
  console.log(msg);
  if (window.webkit != undefined) {
    if (window.webkit.messageHandlers.mfAppInterface != undefined) {
      window.webkit.messageHandlers.mfAppInterface.postMessage(msg);
    }
  }
  if (window.mfAppInterface != undefined) {
    window.mfAppInterface.postMessage(msg);
  }
};
export const nativeUpdateFab = (hidden) => {
  let actionInfo = {
    actionType: 'updateChatFab',
    chatFab: { hideFabOverlay: hidden },
  };
  invokeNative(JSON.stringify(actionInfo));
};

export const nativeAuthenticateUser = (browserUrl, e2eID = '') => {
  const props = {
    actionType: 'openPage',
    pageType: 'l2WebView',
    extraParameters: {
      browserUrl,
      e2eID,
    },
  };
  invokeNative(JSON.stringify(props));
};

export const nativeRenewTokens = (browserUrl, e2eRequestId = '') => {
  const props = {
    actionType: 'openPage',
    pageType: 'renewTokens',
    extraParameters: {
      browserUrl,
      e2eRequestId,
    },
  };
  invokeNative(JSON.stringify(props));
};
