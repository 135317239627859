import api from './../service/apiService';
import { apiUrl } from './../constants/apiUrl';
import { PAGEACTIVITY } from './../constants/actionType';
import { Utils } from './../util/utils';

let oldPage = null,
  newPage = null,
  newPageApiFetched = null;
const PageLoad = 'PageLoad',
  ApiLoad = 'ApiLoad',
  ApiTiming = 'ApiTiming',
  logUrl = apiUrl().kafkaPageLogs;
let isLoadPageAction = false;
let pgLoggerObj = '';
const IH_APP_LOGS = 'applogs';
const IH_PAGE_LOGS = 'pagelogs';

export const logKafka = (logType, logSubType, logMessage, logUrl, logName, visitid) => {
  let kafkaErrorMessage = '';

  if (logName === IH_APP_LOGS) {
    kafkaErrorMessage = { logtype: logType, logsubtype: logSubType, lastupdated: new Date().toUTCString(), logmessage: logMessage };
  } else if (logName === IH_PAGE_LOGS) {
    kafkaErrorMessage = logMessage;
  }

  const logs = getLogMessage(logName, kafkaErrorMessage, visitid);

  api(logUrl, 'POST', logs, 'text/plain');
};

export const logToKibanna = (data, level) => {
  try {
    let logmsg = data ? data + ' JS_NOTIFY_ACCESSORIES' : '';
    let KibanaLogMessage = {
      level: level === 'log' ? 'INFO' : 'ERROR',
      message: logmsg ? logmsg : '',
    };
    const url = apiUrl().jsNotify;
    const request = JSON.stringify(KibanaLogMessage);
    return new Promise((resolve, reject) => {
      api(url, 'POST', request)
        .then((response) => {
          const logResponse = response.data ? response.data : {};
          resolve(logResponse);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } catch (ex) {
    console.log(ex);
  }
};

export const updateisLogRequired = (val) => {
  isLoadPageAction = val;
};

export const getPgLoggerObj = (obj) => {
  if (Utils.isNotNull(obj.enableApiTiming) && obj.enableApiTiming) pgLoggerObj += 'ApiTiming';
  if (Utils.isNotNull(obj.enablePageLoad) && obj.enablePageLoad) pgLoggerObj += ',PageLoad';
  if (Utils.isNotNull(obj.enableUserAction) && obj.enableUserAction) pgLoggerObj += ',USERACTION';

  if (pgLoggerObj !== '') pgLoggerObj = pgLoggerObj.replace(/^,|,$/g, '');
};

function getLogMessage(topic, objectList, visitid) {
  var log = topic + '||visitid=' + visitid;
  // if (topic === 'pagelogs') {
  //     log = log + '||prvpagename=prevpage_name';
  // }
  for (var key in objectList) {
    log = log + '||' + key + '=' + objectList[key];
  }
  if (topic === IH_APP_LOGS) {
    log = log + '||logsource=UI';
  }
  return log;
}

export const checktime = (pageDetails) => {
  pageDetails.mount && (newPage = pageDetails);
  // (oldPage || (oldPage = pageDetails) ) &&
  pageDetails.unmount && (oldPage = pageDetails);
  pageDetails.apiFetched && (newPageApiFetched = pageDetails);
  pageDetails.mount &&
    newPage &&
    oldPage &&
    newPage.mount > oldPage.unmount &&
    (() => {
      logPageInfo(pageInfo(PageLoad, newPage.mount - oldPage.unmount));
    })();
  pageDetails.apiFetched &&
    newPage &&
    newPageApiFetched &&
    newPageApiFetched.apiFetched > newPage.mount &&
    (() => {
      logPageInfo(pageInfo(ApiLoad, newPageApiFetched.apiFetched - newPage.mount));
    })();
};

export const updateVisitId = (visitid) => {
  visitid && (oldPage = Object.assign({}, oldPage, { visitid: visitid }));
};
export const logApiTime = (api) => {
  //(api.url.indexOf('/kafka') === -1) && (api.url.indexOf('localhost') === -1) && logPageInfo(pageInfo(ApiTiming, api.time, api));
  (api.url.indexOf('localhost') === -1 && logPageInfo(pageInfo(ApiTiming, api.time, api))) ||
    (api.pathName === '/proxy' && JSON.parse(api.requestData).RequestUrl.indexOf('kafka') === -1 && logPageInfo(pageInfo(ApiTiming, api.time, api)));
};

export const pageInfo = (pageAction, time, apiRes) => {
  return {
    previousPage: oldPage.page,
    currentPage: newPage.page,
    pageUrl: (apiRes && apiRes.url) || newPage.pageURL,
    action: pageAction,
    time: time,
    reqData: (apiRes && apiRes.requestData) || 'component Load',
    noSSNCheck: newPage.noSSNCheck || false,
    visitid: newPage.visitid,
    pageSubname: (apiRes && apiRes.pathName && apiRes.pathName + '_' + new Date().getTime()) || newPage.page + '_' + new Date().getTime(),
  };
};

export const logPageInfo = (pageInfo) => {
  let pagejsoninfo = { info: (pageInfo.noSSNCheck && 'NO_SSN') || pageInfo.reqData };

  // let loadTime = window.performance.timing.loadEventEnd - window.performance.timing.responseEnd;
  let kafkaPageMessage = {
    prvpagename: pageInfo.previousPage || 'prevpage_name', //(appState.prevPage && appState.prevPage.pathname  )|| 'prevpage_name',
    pagename: pageInfo.currentPage, //appState.currentPage.pathname,
    pagesubname: pageInfo.pageSubname, //appState.currentPage.pathname,
    pageurl: pageInfo.pageUrl, //window.location.href,
    action: pageInfo.action, //"PageLoad",
    duration: Math.floor(pageInfo.time),
    tsp: pageInfo.time,
    pagejson: JSON.stringify(pagejsoninfo),
    lastupdated: getTimeInEST(),
  };

  logKafka('PAGE', pageInfo.currentPage, kafkaPageMessage, logUrl, 'pagelogs', pageInfo.visitid);
};

export const logOtherInfo = (otherInfo) => {
  let kafkaPageMessage = {
    pagename: otherInfo.pageName,
    pagesubname: otherInfo.pageSubname + '_' + new Date().getTime(),
    action: otherInfo.action, //"PageLoad",
    tsp: otherInfo.time,
    lastupdated: getTimeInEST(),
    pagejson: otherInfo.message,
    duration: 0,
  };

  if (Utils.isNotNull(otherInfo) && Utils.isNotNull(otherInfo.action) && Utils.isNotNull(pgLoggerObj) && pgLoggerObj.indexOf(otherInfo.action) > -1) {
    logKafka('PAGE', otherInfo.pageName, kafkaPageMessage, logUrl, 'pagelogs', otherInfo.visitid);
  }

  if (isLoadPageAction) {
    logKafka('PAGE', otherInfo.pageName, kafkaPageMessage, logUrl, 'pagelogs', otherInfo.visitid);
  }
};

export const detailsInfo = (pageName, visitid, pageSubname, logMsg, actionType = null) => {
  return {
    pageName: pageName,
    action: actionType === PAGEACTIVITY.UA ? PAGEACTIVITY.UA : 'pageAction',
    time: new Date().toUTCString(),
    visitid: visitid,
    pageSubname: pageSubname,
    message: logMsg,
  };
};

const getTimeInEST = () => {
  return new Date().toUTCString();
  //TBD
  // =2020-02-09 22:10:33.799 // logging should be in EST TIME in ISO format
  //return new Date().toISOString().replace('T', ' ').replace('Z', '');
  // const date = new Date();
  // const estLocaleTime = date.toLocaleString("en-US", {timeZone: "America/New_York"});
  // const estTime = new Date(estLocaleTime);
  // let estTimeFormat = estTime.toLocaleTimeString().slice(0,estTime.toLocaleTimeString().length - 3);
  // estTimeFormat = estTimeFormat.length === 8 ? estTimeFormat : '0'+estTimeFormat;
  // const month = (estTime.getMonth()+1)  >= 10 ? (estTime.getMonth()+1) : '0'+(estTime.getMonth()+1);
  // const dateFormat = estTime.getDate() >= 10 ? estTime.getDate() : '0' + estTime.getDate() ;
  // const timeString = estTime.getFullYear()+'-'+
  //                    month +'-'+
  //                    dateFormat + ' '+
  //                    estTimeFormat + '.'+
  //                    date.getMilliseconds();
  // return timeString;
};
export const updatePageLogs = (appState, visitid, noSSNCheck) => {
  let pagejsoninfo = {};
  //let logUrl = apiUrl().kafkaPageLogs;
  pagejsoninfo.info = 'info';
  if (noSSNCheck) {
    pagejsoninfo.info = 'NO_SSN';
  }
  let loadTime = window.performance.timing.loadEventEnd - window.performance.timing.responseEnd;
  // let kafkaPageMessage = {
  //     "prvpagename": (appState.prevPage && appState.prevPage.pathname) || 'prevpage_name',
  //     "pagename": appState.currentPage.pathname,
  //     "pagesubname": appState.currentPage.pathname + '_' + new Date().getTime(),
  //     "pageurl": window.location.href,
  //     "action": "PageLoad",
  //     "duration": Math.floor(loadTime),
  //     "tsp": loadTime,
  //     "pagejson": JSON.stringify(pagejsoninfo),
  //     "lastupdated": getTimeInEST()
  // };

  //logKafka('PAGE', appState.currentPage.pathname, kafkaPageMessage, logUrl, 'pagelogs', visitid);
};

export const catchJSException = (pageName, visitid, pageSubname, error) => {
  let errMsg = '';
  console.log(error);
  if (Utils.isNotNull(error)) {
    if (Utils.isNotNull(error.stack) && error.stack !== '') {
      errMsg = error.stack;
    } else if (Utils.isNotNull(error.message) && error.message !== '') {
      errMsg = error.message + ': js error in ' + pageName + ' in ' + pageSubname;
    } else {
      errMsg = 'js error in ' + pageName + ' in ' + pageSubname;
    }
  }

  let getErrorLogUrl = apiUrl().kafkaErrorAppLogs;
  logKafka('JSERROR', pageName + '_' + pageSubname, errMsg, getErrorLogUrl, IH_APP_LOGS, visitid);
};

export const errCheck = (obj) => {
  return obj !== undefined && obj !== null;
};
