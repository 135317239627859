const initialState = {};
function faqDetails(state = initialState, action) {
  switch (action.type) {
    case 'FAQFROMJSON': {
      Object.assign(state, {
        faqExp: action.value,
      });
      return state;
    }
    case 'FAQFROMAPI': {
      Object.assign(state, {
        faqExp: action.value,
      });
      return state;
    }
    default:
      return state;
  }
}
export default faqDetails;
